/***************************************************
	GENERIC GLOBAL STYLES
***************************************************/

// Remove text-shadow in selection highlight:h5bp.com/i// These selection declarations have to be separate.// Customize the background color to match your design.
::-moz-selection {
	text-shadow: none;
	color: $white;
	background: $link-color;
}

::selection {
	text-shadow: none;
	color: $white;
	background: $link-color;
}

// A better looking default horizontal rule
hr {
	position: relative;
	display: block;
	height: rem(1px);
	margin: 1em 0;
	padding: 0;
	border: 0;
	border-top: rem(1px) solid $box-border-color;
	overflow: visible;
	&:before {
		position: absolute;
		top: -50%;
		left: 50%;
		display: inline-block;
		content: attr(data-text);
		padding: 0 0.5em;
		text-align: center;
		@include transform(translate(-50%, -50%));
	}
}

.hr-dotted {
	border-top: rem(1px) dashed $box-border-color;
}

// Remove the gap between images and the bottom of their containers:h5bp.com/i/440
img {
	vertical-align: middle;
}

// Remove default fieldset styles.
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

// Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}

// Clearfix
.clearfix:before,
.clearfix:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.clearfix:after {
	clear: both;
}

*, :before, :after {
	box-sizing: border-box;

	/**
	Relative letter spacing values must be
	placed on each element to compute directly
	upon the element's font-size.
	**/
	letter-spacing: $base-letter-spacing;
}

// default image rendering
img {
	max-width: 100%;
	height: auto;
}

table, thead, tbody, tfoot {
	vertical-align: top;
	width: 100%;
}

.no-margin { margin: 0 !important; }

.block {
	display: block;
}

.block-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		display: block !important;
	}
}

.inline-block {
	display: inline-block;
}

.inline-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		display: inline !important;
	}
}

// Add this class to elements that should only be visible to screen readers
// https://webaim.org/techniques/css/invisiblecontent/
.sr-only {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.invisible {
	visibility: hidden;
}

.visible {
	visibility: visible;
}

.hidden {
	display: none;
}

.force-visible {
	display: block!important;
}
