//** Primary Font Files: Outfit **//

/*
Variable font for modern browsers, set to range that we use (300 and 400).
If in future we wish to use more weights, we simply adjust the range
to the new low and high values (e.g. 200 500).
*/
@supports (font-variation-settings: normal) {
  @font-face {
    font-family: "Outfit";
    src: url("../../fonts/outfit/Outfit-Variable.woff2") format("woff2");
    font-weight: 200 400; // range of weights we use (lowest highest)
    font-display: swap;
  }
}

/*
Fallback for non-modern browsers. If in future we choose to use more weights,
we should first determine if these fallbacks are even needed at that point
in time (depending on browser support for variable fonts) and then add them
if so. They can be downloaded from the Outfit GitHub repo.
https://github.com/Outfitio/Outfit-Fonts
*/
@supports not (font-variation-settings: normal) {
	@font-face {
		font-family: "Outfit";
		src: url("../../fonts/outfit/Outfit-ExtraLight.ttf") format("truetype"),
				 url("../../fonts/outfit/Outfit-ExtraLight.otf") format("opentype");
		font-weight: 200;
		font-display: swap;
	}

  @font-face {
		font-family: "Outfit";
		src: url("../../fonts/outfit/Outfit-Light.ttf") format("truetype"),
				 url("../../fonts/outfit/Outfit-Light.otf") format("opentype");
		font-weight: 300;
		font-display: swap;
	}

	@font-face {
		font-family: "Outfit";
		src: url("../../fonts/outfit/Outfit-Regular.ttf") format("truetype"),
				 url("../../fonts/outfit/Outfit-Regular.otf") format("opentype");
		font-weight: 400;
		font-display: swap;
	}

	@font-face {
		font-family: "Outfit";
		src: url("../../fonts/outfit/Outfit-Medium.ttf") format("truetype"),
		     url("../../fonts/outfit/Outfit-Medium.otf") format("opentype");
		font-weight: 500;
		font-display: swap;
	}
}
