.flow-progress-indicator {
  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
}

.flow-progress-indicator__bar {
  min-width: rem(30px);
  max-width: rem(55px);
  height: rem(7px);
  border-radius: rem(10px);
  margin-right: $spacer-xsmall;
  background: $medium-light-gray;
  overflow: hidden;
  flex: 1;
  transform: translateY(rem(8px));

  &.hide-mobile {
    display: none;
    @media only screen and (min-width: $breakpoint-small) {
      display: block;
    }
  }
}

.flow-progress-indicator__bar-complete{
  display: block;
  border-radius: rem(10px);
  background: $avocado;
  height: 100%;

  // set dynamically by component
  width: 0;
  transition: width 1s cubic-bezier(0.01, 0.03, 0.03, 1);
}

.flow-progress-indicator__step-label {
  line-height: rem(21px);
}
