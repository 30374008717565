.inline-grid {
	display: inline-grid;
}

.justify-end {
	justify-self: end;
}

.justify-end-for-large-up {
	@media screen and (min-width: $breakpoint-medium) {
		justify-self: end;
	}
}

.justify-end-for-small-up {
	@media screen and (min-width: $breakpoint-small) {
		justify-self: end;
	}
}

.row-table {
	@extend .row;
	display: table;

	// Makes FF and IE render display:table the same way as Chrome:
	// http://www.carsonshold.com/2014/07/css-display-table-cell-child-width-bug-in-firefox-and-ie/
	table-layout: fixed;
}

.row .row-table {
	width: 100% !important;
}

.row-valign-middle {
	@extend .row;
	@include display(flex);
	@include flex-wrap(nowrap);
	@include align-items(center);
}

.row-valign-stretch-for-medium-up {
	@extend .row;
	@media only screen and (min-width: $breakpoint-small) {
		@include display(flex);
	}
}

.row-valign-middle-for-medium-up {
	@extend .row;

	@media only screen and (min-width: $breakpoint-small) {
		@include display(flex);
		@include flex-wrap(nowrap);
		@include align-items(center);
	}
}

.row-valign-middle-for-large-up {
	@extend .row;

	@media only screen and (min-width: $breakpoint-medium) {
		@include display(flex);
		@include flex-wrap(nowrap);
		@include align-items(center);
	}
}

.row-valign-bottom {
	@extend .row;
	@include display(flex);
	@include flex-wrap(nowrap);
	@include align-items(flex-end);
}

.row-valign-bottom-for-medium-up {
	@extend .row;

	@media only screen and (min-width: $breakpoint-small) {
		@include display(flex);
		@include flex-wrap(nowrap);
		@include align-items(flex-end);
	}
}

.no-touchevents .row-hoverable:hover {
	background-color: $light-line-color;
	cursor: pointer;
}

.column-table-cell {
	@extend .column;
	display: table-cell;
	vertical-align: middle;
	height: 100%;
	float: none !important;
}

/* Padding */
.pad-left-20 {
	padding-left: rem(20px);
}
.row {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;
	margin-bottom: 0;
	max-width: 82em; }
	.row:before, .row:after {
		content: " ";
		display: table; }
	.row:after {
		clear: both; }
	.row.collapse > .column,
	.row.collapse > .columns {
		padding-left: 0;
		padding-right: 0; }
	.row.collapse .row {
		margin-left: 0;
		margin-right: 0; }
	.row .row {
		width: auto;
		margin-left: -0.9375em;
		margin-right: -0.9375em;
		margin-top: 0;
		margin-bottom: 0;
		max-width: none; }
		.row .row:before, .row .row:after {
			content: " ";
			display: table; }
		.row .row:after {
			clear: both; }
		.row .row.collapse {
			width: auto;
			margin: 0;
			max-width: none; }
			.row .row.collapse:before, .row .row.collapse:after {
				content: " ";
				display: table; }
			.row .row.collapse:after {
				clear: both; }

.column,
.columns {
	padding-left: 0.9375em;
	padding-right: 0.9375em;
	width: 100%;
	float: left; }

[class*="column"] + [class*="column"]:last-child {
	float: right; }

[class*="column"] + [class*="column"].end {
	float: left; }

.force-inline-block {
	// When using `show-for-small-only` and similar classes, the default display selector will
	// override any native inline html elements. The below snippet is rendered on two lines due to
	// the class on the span:

	// 		<h4>Hello <span class='show-for-small-only>World</span></h4>

	// This behavior is not necessarily what you will always want. Adding the `force-inline-block`
	// class resolves the issue and renders the elements inline-block

	// 		<h4 class='force-inline-block>Hello <span class='show-for-small-only>World</span></h4>

	display: inline-block;
}

@media screen, print {
	.small-push-0 {
		position: relative;
		left: 0%;
		right: auto; }

	.small-pull-0 {
		position: relative;
		right: 0%;
		left: auto; }

	.small-push-1 {
		position: relative;
		left: 8.33333%;
		right: auto; }

	.small-pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto; }

	.small-push-2 {
		position: relative;
		left: 16.66667%;
		right: auto; }

	.small-pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto; }

	.small-push-3 {
		position: relative;
		left: 25%;
		right: auto; }

	.small-pull-3 {
		position: relative;
		right: 25%;
		left: auto; }

	.small-push-4 {
		position: relative;
		left: 33.33333%;
		right: auto; }

	.small-pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto; }

	.small-push-5 {
		position: relative;
		left: 41.66667%;
		right: auto; }

	.small-pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto; }

	.small-push-6 {
		position: relative;
		left: 50%;
		right: auto; }

	.small-pull-6 {
		position: relative;
		right: 50%;
		left: auto; }

	.small-push-7 {
		position: relative;
		left: 58.33333%;
		right: auto; }

	.small-pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto; }

	.small-push-8 {
		position: relative;
		left: 66.66667%;
		right: auto; }

	.small-pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto; }

	.small-push-9 {
		position: relative;
		left: 75%;
		right: auto; }

	.small-pull-9 {
		position: relative;
		right: 75%;
		left: auto; }

	.small-push-10 {
		position: relative;
		left: 83.33333%;
		right: auto; }

	.small-pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto; }

	.small-push-11 {
		position: relative;
		left: 91.66667%;
		right: auto; }

	.small-pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto; }

	.column,
	.columns {
		position: relative;
		padding-left: 0.9375em;
		padding-right: 0.9375em;
		float: left; }

	.small-1 {
		width: 8.33333%; }

	.small-2 {
		width: 16.66667%; }

	.small-3 {
		width: 25%; }

	.small-4 {
		width: 33.33333%; }

	.small-5 {
		width: 41.66667%; }

	.small-6 {
		width: 50%; }

	.small-7 {
		width: 58.33333%; }

	.small-8 {
		width: 66.66667%; }

	.small-9 {
		width: 75%; }

	.small-10 {
		width: 83.33333%; }

	.small-11 {
		width: 91.66667%; }

	.small-12 {
		width: 100%; }

	.small-offset-0 {
		margin-left: 0% !important; }

	.small-offset-1 {
		margin-left: 8.33333% !important; }

	.small-offset-2 {
		margin-left: 16.66667% !important; }

	.small-offset-3 {
		margin-left: 25% !important; }

	.small-offset-4 {
		margin-left: 33.33333% !important; }

	.small-offset-5 {
		margin-left: 41.66667% !important; }

	.small-offset-6 {
		margin-left: 50% !important; }

	.small-offset-7 {
		margin-left: 58.33333% !important; }

	.small-offset-8 {
		margin-left: 66.66667% !important; }

	.small-offset-9 {
		margin-left: 75% !important; }

	.small-offset-10 {
		margin-left: 83.33333% !important; }

	.small-offset-11 {
		margin-left: 91.66667% !important; }

	.small-reset-order {
		margin-left: 0;
		margin-right: 0;
		left: auto;
		right: auto;
		float: left; }

	.column.small-centered,
	.columns.small-centered {
		margin-left: auto;
		margin-right: auto;
		float: none; }

	.column.small-uncentered,
	.columns.small-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left; }

	.column.small-centered:last-child,
	.columns.small-centered:last-child {
		float: none; }

	.column.small-uncentered:last-child,
	.columns.small-uncentered:last-child {
		float: left; }

	.column.small-uncentered.opposite,
	.columns.small-uncentered.opposite {
		float: right; } }
@media only screen and (min-width: $breakpoint-small), print {
	.medium-push-0 {
		position: relative;
		left: 0%;
		right: auto; }

	.medium-pull-0 {
		position: relative;
		right: 0%;
		left: auto; }

	.medium-push-1 {
		position: relative;
		left: 8.33333%;
		right: auto; }

	.medium-pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto; }

	.medium-push-2 {
		position: relative;
		left: 16.66667%;
		right: auto; }

	.medium-pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto; }

	.medium-push-3 {
		position: relative;
		left: 25%;
		right: auto; }

	.medium-pull-3 {
		position: relative;
		right: 25%;
		left: auto; }

	.medium-push-4 {
		position: relative;
		left: 33.33333%;
		right: auto; }

	.medium-pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto; }

	.medium-push-5 {
		position: relative;
		left: 41.66667%;
		right: auto; }

	.medium-pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto; }

	.medium-push-6 {
		position: relative;
		left: 50%;
		right: auto; }

	.medium-pull-6 {
		position: relative;
		right: 50%;
		left: auto; }

	.medium-push-7 {
		position: relative;
		left: 58.33333%;
		right: auto; }

	.medium-pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto; }

	.medium-push-8 {
		position: relative;
		left: 66.66667%;
		right: auto; }

	.medium-pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto; }

	.medium-push-9 {
		position: relative;
		left: 75%;
		right: auto; }

	.medium-pull-9 {
		position: relative;
		right: 75%;
		left: auto; }

	.medium-push-10 {
		position: relative;
		left: 83.33333%;
		right: auto; }

	.medium-pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto; }

	.medium-push-11 {
		position: relative;
		left: 91.66667%;
		right: auto; }

	.medium-pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto; }

	.column,
	.columns {
		position: relative;
		padding-left: 0.9375em;
		padding-right: 0.9375em;
		float: left; }

	.medium-1 {
		width: 8.33333%; }

	.medium-2 {
		width: 16.66667%; }

	.medium-3 {
		width: 25%; }

	.medium-4 {
		width: 33.33333%; }

	.medium-5 {
		width: 41.66667%; }

	.medium-6 {
		width: 50%; }

	.medium-7 {
		width: 58.33333%; }

	.medium-8 {
		width: 66.66667%; }

	.medium-9 {
		width: 75%; }

	.medium-10 {
		width: 83.33333%; }

	.medium-11 {
		width: 91.66667%; }

	.medium-12 {
		width: 100%; }

	.medium-offset-0 {
		margin-left: 0% !important; }

	.medium-offset-1 {
		margin-left: 8.33333% !important; }

	.medium-offset-2 {
		margin-left: 16.66667% !important; }

	.medium-offset-3 {
		margin-left: 25% !important; }

	.medium-offset-4 {
		margin-left: 33.33333% !important; }

	.medium-offset-5 {
		margin-left: 41.66667% !important; }

	.medium-offset-6 {
		margin-left: 50% !important; }

	.medium-offset-7 {
		margin-left: 58.33333% !important; }

	.medium-offset-8 {
		margin-left: 66.66667% !important; }

	.medium-offset-9 {
		margin-left: 75% !important; }

	.medium-offset-10 {
		margin-left: 83.33333% !important; }

	.medium-offset-11 {
		margin-left: 91.66667% !important; }

	.medium-reset-order {
		margin-left: 0;
		margin-right: 0;
		left: auto;
		right: auto;
		float: left; }

	.column.medium-centered,
	.columns.medium-centered {
		margin-left: auto;
		margin-right: auto;
		float: none; }

	.column.medium-uncentered,
	.columns.medium-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left; }

	.column.medium-centered:last-child,
	.columns.medium-centered:last-child {
		float: none; }

	.column.medium-uncentered:last-child,
	.columns.medium-uncentered:last-child {
		float: left; }

	.column.medium-uncentered.opposite,
	.columns.medium-uncentered.opposite {
		float: right; }

	.push-0 {
		position: relative;
		left: 0%;
		right: auto; }

	.pull-0 {
		position: relative;
		right: 0%;
		left: auto; }

	.push-1 {
		position: relative;
		left: 8.33333%;
		right: auto; }

	.pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto; }

	.push-2 {
		position: relative;
		left: 16.66667%;
		right: auto; }

	.pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto; }

	.push-3 {
		position: relative;
		left: 25%;
		right: auto; }

	.pull-3 {
		position: relative;
		right: 25%;
		left: auto; }

	.push-4 {
		position: relative;
		left: 33.33333%;
		right: auto; }

	.pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto; }

	.push-5 {
		position: relative;
		left: 41.66667%;
		right: auto; }

	.pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto; }

	.push-6 {
		position: relative;
		left: 50%;
		right: auto; }

	.pull-6 {
		position: relative;
		right: 50%;
		left: auto; }

	.push-7 {
		position: relative;
		left: 58.33333%;
		right: auto; }

	.pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto; }

	.push-8 {
		position: relative;
		left: 66.66667%;
		right: auto; }

	.pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto; }

	.push-9 {
		position: relative;
		left: 75%;
		right: auto; }

	.pull-9 {
		position: relative;
		right: 75%;
		left: auto; }

	.push-10 {
		position: relative;
		left: 83.33333%;
		right: auto; }

	.pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto; }

	.push-11 {
		position: relative;
		left: 91.66667%;
		right: auto; }

	.pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto; } }
@media only screen and (min-width: $breakpoint-medium) {
	.large-push-0 {
		position: relative;
		left: 0%;
		right: auto; }

	.large-pull-0 {
		position: relative;
		right: 0%;
		left: auto; }

	.large-push-1 {
		position: relative;
		left: 8.33333%;
		right: auto; }

	.large-pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto; }

	.large-push-2 {
		position: relative;
		left: 16.66667%;
		right: auto; }

	.large-pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto; }

	.large-push-3 {
		position: relative;
		left: 25%;
		right: auto; }

	.large-pull-3 {
		position: relative;
		right: 25%;
		left: auto; }

	.large-push-4 {
		position: relative;
		left: 33.33333%;
		right: auto; }

	.large-pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto; }

	.large-push-5 {
		position: relative;
		left: 41.66667%;
		right: auto; }

	.large-pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto; }

	.large-push-6 {
		position: relative;
		left: 50%;
		right: auto; }

	.large-pull-6 {
		position: relative;
		right: 50%;
		left: auto; }

	.large-push-7 {
		position: relative;
		left: 58.33333%;
		right: auto; }

	.large-pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto; }

	.large-push-8 {
		position: relative;
		left: 66.66667%;
		right: auto; }

	.large-pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto; }

	.large-push-9 {
		position: relative;
		left: 75%;
		right: auto; }

	.large-pull-9 {
		position: relative;
		right: 75%;
		left: auto; }

	.large-push-10 {
		position: relative;
		left: 83.33333%;
		right: auto; }

	.large-pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto; }

	.large-push-11 {
		position: relative;
		left: 91.66667%;
		right: auto; }

	.large-pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto; }

	.column,
	.columns {
		position: relative;
		padding-left: 0.9375em;
		padding-right: 0.9375em;
		float: left; }

	.large-1 {
		width: 8.33333%; }

	.large-2 {
		width: 16.66667%; }

	.large-3 {
		width: 25%; }

	.large-4 {
		width: 33.33333%; }

	.large-5 {
		width: 41.66667%; }

	.large-6 {
		width: 50%; }

	.large-7 {
		width: 58.33333%; }

	.large-8 {
		width: 66.66667%; }

	.large-9 {
		width: 75%; }

	.large-10 {
		width: 83.33333%; }

	.large-11 {
		width: 91.66667%; }

	.large-12 {
		width: 100%; }

	.large-offset-0 {
		margin-left: 0% !important; }

	.large-offset-1 {
		margin-left: 8.33333% !important; }

	.large-offset-2 {
		margin-left: 16.66667% !important; }

	.large-offset-3 {
		margin-left: 25% !important; }

	.large-offset-4 {
		margin-left: 33.33333% !important; }

	.large-offset-5 {
		margin-left: 41.66667% !important; }

	.large-offset-6 {
		margin-left: 50% !important; }

	.large-offset-7 {
		margin-left: 58.33333% !important; }

	.large-offset-8 {
		margin-left: 66.66667% !important; }

	.large-offset-9 {
		margin-left: 75% !important; }

	.large-offset-10 {
		margin-left: 83.33333% !important; }

	.large-offset-11 {
		margin-left: 91.66667% !important; }

	.large-reset-order {
		margin-left: 0;
		margin-right: 0;
		left: auto;
		right: auto;
		float: left; }

	.column.large-centered,
	.columns.large-centered {
		margin-left: auto;
		margin-right: auto;
		float: none; }

	.column.large-uncentered,
	.columns.large-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left; }

	.column.large-centered:last-child,
	.columns.large-centered:last-child {
		float: none; }

	.column.large-uncentered:last-child,
	.columns.large-uncentered:last-child {
		float: left; }

	.column.large-uncentered.opposite,
	.columns.large-uncentered.opposite {
		float: right; }

	.push-0 {
		position: relative;
		left: 0%;
		right: auto; }

	.pull-0 {
		position: relative;
		right: 0%;
		left: auto; }

	.push-1 {
		position: relative;
		left: 8.33333%;
		right: auto; }

	.pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto; }

	.push-2 {
		position: relative;
		left: 16.66667%;
		right: auto; }

	.pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto; }

	.push-3 {
		position: relative;
		left: 25%;
		right: auto; }

	.pull-3 {
		position: relative;
		right: 25%;
		left: auto; }

	.push-4 {
		position: relative;
		left: 33.33333%;
		right: auto; }

	.pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto; }

	.push-5 {
		position: relative;
		left: 41.66667%;
		right: auto; }

	.pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto; }

	.push-6 {
		position: relative;
		left: 50%;
		right: auto; }

	.pull-6 {
		position: relative;
		right: 50%;
		left: auto; }

	.push-7 {
		position: relative;
		left: 58.33333%;
		right: auto; }

	.pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto; }

	.push-8 {
		position: relative;
		left: 66.66667%;
		right: auto; }

	.pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto; }

	.push-9 {
		position: relative;
		left: 75%;
		right: auto; }

	.pull-9 {
		position: relative;
		right: 75%;
		left: auto; }

	.push-10 {
		position: relative;
		left: 83.33333%;
		right: auto; }

	.pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto; }

	.push-11 {
		position: relative;
		left: 91.66667%;
		right: auto; }

	.pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto; } }
/* small displays */
@media only screen {
	.stack-for-small-only {
		display: block;
	}
	.show-for-small-only, .show-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down {
		display: inherit !important; }

	.hide-for-small-only, .hide-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down {
		display: none !important; }

	.visible-for-small-only, .visible-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto; }

	.full-height-medium-up { display: block; }

	.text-left-for-small-only { text-align: left; }

	.hidden-for-small-only, .hidden-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down {
		position: absolute !important;
		height: rem(1px);
		width: rem(1px);
		overflow: hidden;
		clip: rect(rem(1px), rem(1px), rem(1px), rem(1px)); }

	table.show-for-small-only, table.show-for-small-up, table.show-for-small, table.show-for-small-down, table.hide-for-medium-only, table.hide-for-medium-up, table.hide-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down {
		display: table !important; }

	thead.show-for-small-only, thead.show-for-small-up, thead.show-for-small, thead.show-for-small-down, thead.hide-for-medium-only, thead.hide-for-medium-up, thead.hide-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down {
		display: table-header-group !important; }

	tbody.show-for-small-only, tbody.show-for-small-up, tbody.show-for-small, tbody.show-for-small-down, tbody.hide-for-medium-only, tbody.hide-for-medium-up, tbody.hide-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down {
		display: table-row-group !important; }

	tr.show-for-small-only, tr.show-for-small-up, tr.show-for-small, tr.show-for-small-down, tr.hide-for-medium-only, tr.hide-for-medium-up, tr.hide-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down {
		display: table-row !important; }

	th.show-for-small-only, td.show-for-small-only, th.show-for-small-up, td.show-for-small-up, th.show-for-small, td.show-for-small, th.show-for-small-down, td.show-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.hide-for-medium-up, td.hide-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down {
		display: table-cell !important; } }
/* medium displays */
@media only screen and (min-width: $breakpoint-small) {
	.stack-for-small-only {
		display: inline-block;
	}
	.hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .show-for-medium-only, .show-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .show-for-large-down {
		display: inherit !important; }

	.show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .hide-for-medium-only, .hide-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .hide-for-large-down {
		display: none !important; }

	.full-height-medium-up {
		@include display(flex);
		@include align-items(stretch);

		// ensure full width of immediate child
		> * { width: 100%; }
	}

	.row-column-full-height-medium-up {
		@include display(flex);
		@include align-items(stretch);
		@include flex-wrap(wrap);

		// Safari counts before and after pseudo elements as actual content, when it shouldn't.
		// This results in unexpected dimension calculations when adding child nodes to
		// a flexbox parent node.  A hacky fix for this is to tell browsers to disregard
		// the before and after pseudo elements.
		// For reference: http://stackoverflow.com/questions/34250282/flexbox-safari-bug-flex-wrap
		&:before, &:after {
			content: normal !important;
		}
	}

	.text-left-for-small-only { text-align: right; }

	.hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .visible-for-medium-only, .visible-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .visible-for-large-down {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto; }

	.visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .hidden-for-medium-only, .hidden-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .hidden-for-large-down {
		position: absolute !important;
		height: rem(1px);
		width: rem(1px);
		overflow: hidden;
		clip: rect(rem(1px), rem(1px), rem(1px), rem(1px)); }

	table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.show-for-medium-only, table.show-for-medium-up, table.show-for-medium, table.show-for-medium-down, table.hide-for-large-only, table.hide-for-large-up, table.hide-for-large, table.show-for-large-down {
		display: table !important; }

	thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.show-for-medium-only, thead.show-for-medium-up, thead.show-for-medium, thead.show-for-medium-down, thead.hide-for-large-only, thead.hide-for-large-up, thead.hide-for-large, thead.show-for-large-down {
		display: table-header-group !important; }

	tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.show-for-medium-only, tbody.show-for-medium-up, tbody.show-for-medium, tbody.show-for-medium-down, tbody.hide-for-large-only, tbody.hide-for-large-up, tbody.hide-for-large, tbody.show-for-large-down {
		display: table-row-group !important; }

	tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.show-for-medium-only, tr.show-for-medium-up, tr.show-for-medium, tr.show-for-medium-down, tr.hide-for-large-only, tr.hide-for-large-up, tr.hide-for-large, tr.show-for-large-down {
		display: table-row !important; }

	th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.show-for-medium-only, td.show-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.show-for-medium, td.show-for-medium, th.show-for-medium-down, td.show-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.hide-for-large-up, td.hide-for-large-up, th.hide-for-large, td.hide-for-large, th.show-for-large-down, td.show-for-large-down {
		display: table-cell !important; } }
/* large displays */
@media only screen and (min-width: $breakpoint-medium) {
	.stack-for-small-only {
		display: inline-block;
	}
	.hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .show-for-large-only, .show-for-large-up, .show-for-large, .show-for-large-down {
		display: inherit !important; }

	.show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .hide-for-large-only, .hide-for-large-up, .hide-for-large, .hide-for-large-down {
		display: none !important; }

	.hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .visible-for-large-only, .visible-for-large-up, .visible-for-large, .visible-for-large-down {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto; }

	.visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .hidden-for-large-only, .hidden-for-large-up, .hidden-for-large, .hidden-for-large-down {
		position: absolute !important;
		height: rem(1px);
		width: rem(1px);
		overflow: hidden;
		clip: rect(rem(1px), rem(1px), rem(1px), rem(1px)); }

	table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.show-for-large-only, table.show-for-large-up, table.show-for-large, table.show-for-large-down {
		display: table !important; }

	thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.show-for-large-only, thead.show-for-large-up, thead.show-for-large, thead.show-for-large-down {
		display: table-header-group !important; }

	tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.show-for-large-only, tbody.show-for-large-up, tbody.show-for-large, tbody.show-for-large-down {
		display: table-row-group !important; }

	tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.show-for-large-only, tr.show-for-large-up, tr.show-for-large, tr.show-for-large-down {
		display: table-row !important; }

	th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.show-for-large-only, td.show-for-large-only, th.show-for-large-up, td.show-for-large-up, th.show-for-large, td.show-for-large, th.show-for-large-down, td.show-for-large-down {
		display: table-cell !important; } }
/* xlarge displays */
@media only screen and (min-width: $breakpoint-large) {
	.stack-for-small-only {
		display: inline-block;
	}
	.hide-for-small-only, .show-for-small-up, .hide-for-small, .hide-for-small-down, .hide-for-medium-only, .show-for-medium-up, .hide-for-medium, .hide-for-medium-down, .hide-for-large-only, .show-for-large-up, .hide-for-large, .hide-for-large-down {
		display: inherit !important; }

	.show-for-small-only, .hide-for-small-up, .show-for-small, .show-for-small-down, .show-for-medium-only, .hide-for-medium-up, .show-for-medium, .show-for-medium-down, .show-for-large-only, .hide-for-large-up, .show-for-large, .show-for-large-down {
		display: none !important; }

	.hidden-for-small-only, .visible-for-small-up, .hidden-for-small, .hidden-for-small-down, .hidden-for-medium-only, .visible-for-medium-up, .hidden-for-medium, .hidden-for-medium-down, .hidden-for-large-only, .visible-for-large-up, .hidden-for-large, .hidden-for-large-down {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto; }

	.visible-for-small-only, .hidden-for-small-up, .visible-for-small, .visible-for-small-down, .visible-for-medium-only, .hidden-for-medium-up, .visible-for-medium, .visible-for-medium-down, .visible-for-large-only, .hidden-for-large-up, .visible-for-large, .visible-for-large-down {
		position: absolute !important;
		height: rem(1px);
		width: rem(1px);
		overflow: hidden;
		clip: rect(rem(1px), rem(1px), rem(1px), rem(1px)); }

	table.hide-for-small-only, table.show-for-small-up, table.hide-for-small, table.hide-for-small-down, table.hide-for-medium-only, table.show-for-medium-up, table.hide-for-medium, table.hide-for-medium-down, table.hide-for-large-only, table.show-for-large-up, table.hide-for-large, table.hide-for-large-down {
		display: table !important; }

	thead.hide-for-small-only, thead.show-for-small-up, thead.hide-for-small, thead.hide-for-small-down, thead.hide-for-medium-only, thead.show-for-medium-up, thead.hide-for-medium, thead.hide-for-medium-down, thead.hide-for-large-only, thead.show-for-large-up, thead.hide-for-large, thead.hide-for-large-down {
		display: table-header-group !important; }

	tbody.hide-for-small-only, tbody.show-for-small-up, tbody.hide-for-small, tbody.hide-for-small-down, tbody.hide-for-medium-only, tbody.show-for-medium-up, tbody.hide-for-medium, tbody.hide-for-medium-down, tbody.hide-for-large-only, tbody.show-for-large-up, tbody.hide-for-large, tbody.hide-for-large-down {
		display: table-row-group !important; }

	tr.hide-for-small-only, tr.show-for-small-up, tr.hide-for-small, tr.hide-for-small-down, tr.hide-for-medium-only, tr.show-for-medium-up, tr.hide-for-medium, tr.hide-for-medium-down, tr.hide-for-large-only, tr.show-for-large-up, tr.hide-for-large, tr.hide-for-large-down {
		display: table-row !important; }

	th.hide-for-small-only, td.hide-for-small-only, th.show-for-small-up, td.show-for-small-up, th.hide-for-small, td.hide-for-small, th.hide-for-small-down, td.hide-for-small-down, th.hide-for-medium-only, td.hide-for-medium-only, th.show-for-medium-up, td.show-for-medium-up, th.hide-for-medium, td.hide-for-medium, th.hide-for-medium-down, td.hide-for-medium-down, th.hide-for-large-only, td.hide-for-large-only, th.show-for-large-up, td.show-for-large-up, th.hide-for-large, td.hide-for-large, th.hide-for-large-down, td.hide-for-large-down {
		display: table-cell !important; } }

/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
	display: inherit !important; }

.hide-for-landscape,
.show-for-portrait {
	display: none !important; }

/* Specific visibility for tables */
table.hide-for-landscape, table.show-for-portrait {
	display: table !important; }

thead.hide-for-landscape, thead.show-for-portrait {
	display: table-header-group !important; }

tbody.hide-for-landscape, tbody.show-for-portrait {
	display: table-row-group !important; }

tr.hide-for-landscape, tr.show-for-portrait {
	display: table-row !important; }

td.hide-for-landscape, td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
	display: table-cell !important; }

@media only screen and (orientation: landscape) {
	.show-for-landscape,
	.hide-for-portrait {
		display: inherit !important; }

	.hide-for-landscape,
	.show-for-portrait {
		display: none !important; }

	/* Specific visibility for tables */
	table.show-for-landscape, table.hide-for-portrait {
		display: table !important; }

	thead.show-for-landscape, thead.hide-for-portrait {
		display: table-header-group !important; }

	tbody.show-for-landscape, tbody.hide-for-portrait {
		display: table-row-group !important; }

	tr.show-for-landscape, tr.hide-for-portrait {
		display: table-row !important; }

	td.show-for-landscape, td.hide-for-portrait,
	th.show-for-landscape,
	th.hide-for-portrait {
		display: table-cell !important; } }
@media only screen and (orientation: portrait) {
	.show-for-portrait,
	.hide-for-landscape {
		display: inherit !important; }

	.hide-for-portrait,
	.show-for-landscape {
		display: none !important; }

	/* Specific visibility for tables */
	table.show-for-portrait, table.hide-for-landscape {
		display: table !important; }

	thead.show-for-portrait, thead.hide-for-landscape {
		display: table-header-group !important; }

	tbody.show-for-portrait, tbody.hide-for-landscape {
		display: table-row-group !important; }

	tr.show-for-portrait, tr.hide-for-landscape {
		display: table-row !important; }

	td.show-for-portrait, td.hide-for-landscape,
	th.show-for-portrait,
	th.hide-for-landscape {
		display: table-cell !important; } }
/* Touch-enabled device targeting */
.show-for-touch {
	display: none !important; }

.hide-for-touch {
	display: inherit !important; }

.touch .show-for-touch {
	display: inherit !important; }

.touch .hide-for-touch {
	display: none !important; }

/* Specific visibility for tables */
table.hide-for-touch {
	display: table !important; }

.touch table.show-for-touch {
	display: table !important; }

thead.hide-for-touch {
	display: table-header-group !important; }

.touch thead.show-for-touch {
	display: table-header-group !important; }

tbody.hide-for-touch {
	display: table-row-group !important; }

.touch tbody.show-for-touch {
	display: table-row-group !important; }

tr.hide-for-touch {
	display: table-row !important; }

.touch tr.show-for-touch {
	display: table-row !important; }

td.hide-for-touch {
	display: table-cell !important; }

.touch td.show-for-touch {
	display: table-cell !important; }

th.hide-for-touch {
	display: table-cell !important; }

.touch th.show-for-touch {
	display: table-cell !important; }

