// MIXINS, FUNCTIONS, AND SHARED CLASSES

@mixin npt-transition($property: all) {
  @include transition($property .2s ease);
}

@mixin npt-border-color($color, $important: false) {
  @if $important {
    border-color: $color !important;
  } @else {
    border-color: $color;
  }
}

@mixin npt-box-shadow($color) {
  box-shadow: 0 0 $npt-focus-box-shadow-spread $color;
}

// Input images: places background images right aligned inside inputs
@mixin background-image-base {
  background-position: calc(100% - #{$npt-padding}) center;
  background-repeat: no-repeat;
  background-size: $npt-background-image-size $npt-background-image-size;
  padding-right: ($npt-padding * 2) + $npt-background-image-size;
}


%npt-hidden {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  opacity: 0.0001; //To allow selenium to treat the element as displayed and clickable.
}

// top level parent: wraps all elements of component (labels, inputs, messages and banners)
.npt-fieldset {
  position: relative;
  padding-top: $npt-spacer;
  padding-bottom: $npt-spacer;
}

// USE THE CLASS ONLY IF YOU MUST TO REMOVE VERTICAL PADDING
// Inputs must have 24px of vertical space between them, so if you use this class to remove
// that spacer (maybe because you have to add some complexity to your input that isn't handled
// by our pre-build components), you MUST re-create that vertical spacing with another element.
.npt-fieldset--no-spacer {
  padding-top: 0;
  padding-bottom: 0;
}

// USE THE CLASS BELOW TO RE-ADD VERTICAL PADDING TO WHATEVER ELEMENT YOU NEED
.npt-spacer {
  padding-top: $npt-spacer;
  padding-bottom: $npt-spacer;
}

// label and info text
%npt-outer-text {
  display: block;
  line-height: $npt-line-height;
  color: $npt-label-color;
  margin-bottom: $npt-helper-text-spacer;
  font-weight: $regular;
}

// checkboxes and radios
.npt-fieldset--selection {
  @extend .npt-fieldset;

  input {
    @extend %npt-hidden;
  }

  label {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-weight: $regular;
    font-size: $npt-font-size;
    color: $npt-label-color;
    line-height: $npt-selection-size-reg;
    padding-left: $npt-selection-label-spacer + $npt-selection-size-reg;

    // custom input stylingz
    &:before, &:after {
      position: absolute;
    }

    // outer circle
    &:before {
      content: '';
      left: 0;
      border: rem(1px) solid $npt-border-color;
      width: $npt-selection-size-reg;
      height: $npt-selection-size-reg;
      background: $white;
      @include npt-transition(border-color);
    }

    &:hover {
      &:before {
        border-color: $npt-selection-hover-color;
      }
    }
  }

  &.npt-disabled {
    label {
      cursor: not-allowed;
      &:before {
        background: $npt-disabled-background-color;
        border-color: $npt-border-color!important;
        color: $npt-disabled-color;
      }
      &:after {
        color: $npt-disabled-color;
      }
      &:hover {
        &:before {
          border-color: $npt-border-color;
        }
      }
    }
  }

  input:checked {
    & + label:before {
      border-color: $npt-selection-color;
    }
    & + label:after {
      opacity: 1;
    }
  }

  input:focus {
    & + label:before {
      @include npt-box-shadow($npt-focus-box-shadow-color);
    }
  }
}

.npt-fieldset--selection-large {
  @extend .npt-fieldset--selection;

  label {
    line-height: $npt-selection-size-large;
    padding-left: $npt-selection-label-spacer + $npt-selection-size-large;
    &:before {
      width: $npt-selection-size-large;
      height: $npt-selection-size-large;
    }
  }
}