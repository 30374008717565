
$popup-duration: 0.15s;
$overlay-duration: 0.15s;

$popup-box-shadow: 0 rem(5px) rem(25px) rem(5px) rgba(0, 0, 0, 0.15);
$popup-border: rem(1px) solid $dark-line-color;
$popup-border-radius: rem(4px);


// Improve popup scrolling experience when active.
.popup-body-scroll-shim {
	overflow: hidden;
}

.popup-wrapper {
	position: fixed;
	z-index: $popup-z;
	overflow: auto;
}
.site-loading, .site-processing {
	.popup-wrapper {
		z-index: 1;
	}
	noscript .popup-wrapper {
		z-index: $popup-z;
	}
}

.popup-wrapper, .popup-overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.popup-overlay {
	position: fixed;
	display: block;
	z-index: 1;
	background-color: rgba(255, 255, 255, 0.5);
	opacity: 0;
	@include transition(opacity $overlay-duration $ease $popup-duration);
}

.popup {
	@include display(flex);
	@include flex-direction(column);

	position: absolute;
	margin: auto auto rem(30px);
	right: 0;
	left: 0;
	// TODO: calculate top inline with iframe helpers
	top: rem(30px);

	border: $popup-border;
	box-shadow: $popup-box-shadow;
	border-radius: $popup-border-radius;
	background-color: $white;
	z-index: 2;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	@include transition(all $popup-duration $ease);
	@include transform(scale(0.9));
	opacity: 0;
	font-size: rem(16px);
	width: 92%;
	max-width: rem(696px);

	@media only screen and (min-width: $breakpoint-medium) {
		max-width: rem(696px);
	}

	.payment-confirmation {
		.bank-label {
			@media only screen and (max-width: $breakpoint-small) {
				display: block;
			}
		}
	}
}

.popup-active .popup-overlay {
	@include transition-delay(0s);
	opacity: 1;
}
.popup-active .popup {
	opacity: 1;
	@include transition-delay($overlay-duration);
	@include transform(scale(1));
}

.popup-icon {
	@media only screen and (min-width: $breakpoint-small) {
		font-size: 2em;
		margin-bottom: rem(5px);
		&.mortar-icon { font-size: 2.5em; }
	}

	@media only screen and (max-width: $breakpoint-small) {
		font-size: 1.5em;
		&.mortar-icon { font-size: 1.8em; }
		margin: rem(10px) rem(5px) rem(10px) 0;
		top: -0.25em;
		left: 0;
		position: absolute;
	}
}

.popup-close {
	@extend .focus-outline;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	display: block;
	padding: 0.5em;
	vertical-align: middle;
	@include box-sizing(content-box);
	text-decoration: none!important;
	i {
		color: $charcoal;
		@include transition(color 0.2s $ease);
		font-size: rem(32px);
	}
	&:hover {
		i {
			color: $black;
		}
	}
}

.popup--form .popup-content {
	overflow-y: initial;
}

.popup-content {
	padding: 2.5em 1.25em 1.25em;

	@media only screen and (min-width: $breakpoint-medium) {
		padding: 2.5em;
	}

	h2 {
		padding: 0 0 1em;
		margin: 0;

		&.center-header {
			@media only screen and (min-width: $breakpoint-small) {
				text-align: center;
			}
		}

		&.with-icon {
			@media only screen and (min-width: $breakpoint-small) {
				text-align: center;

				span.title {
					display: block;
					padding-top: rem(10px);
				}
			}

			@media only screen and (max-width: $breakpoint-small) {
				position: relative;
				padding-left: 2.25em;
			}
		}
	}

	p {
		font-weight: $light;

		li {
			font-size: rem(18px);
			line-height: rem(27px);
		}
		strong {
			font-size: inherit;
		}
	}
}

.popup-buttons {
	display: flex;
	flex-direction: column;
	padding: 0.75em 1.25em;

	[class*="btn"] {
		width: 100%;
		display: block;
		margin-left: 0 !important;

		&:first-child {
			margin-bottom: .8em;
		}
	}

	@media only screen and (min-width: $breakpoint-small) {
		justify-content: flex-end;
		flex-direction: row;
		padding: 1.25em 2.5em;

		[class*="btn"] {
			width: auto;
			margin: 0.25em 0;

			// Reverse the order of the popup buttons for desktop users
			&:first-child {
				margin-bottom: 0.25em;
				&:not(:only-child) {
					margin-right: 1.5em;
				}
			}
		}
	}
}

