// ============================================
// COLORS
// ============================================

$npt-background-color:           $white;
$npt-disabled-background-color:  $light-gray;

$npt-border-color:               $medium-gray;
$npt-hover-border-color:         $medium-gray;

$npt-focus-border-color:         $avocado;
$npt-focus-box-shadow-color:     $apts-green;

$npt-text-color:                 $black;
$npt-placeholder-text-color:     rgba($charcoal, .8);
$npt-focus-text-color:           $white;
$npt-label-color:                $black;
$npt-error-color:                $crimson;
$npt-success-color:              $forest-green;
$npt-disabled-color:             rgba($charcoal, .8);
$npt-icon-color:                 $avocado;

// selection elements
$npt-selection-color:            $avocado;
$npt-selection-hover-color:      $black;

$npt-toggle-text-color:          $white;
$npt-toggle-circle-color:        $white;
$npt-toggle-bg-color-on:         $avocado;
$npt-toggle-bg-color-off:        $medium-gray;

// radio-bar
$radio-filter-background-color:  $avocado;
$radio-filter-focus-color:       $apts-green;
$radio-filter-hover-color:       $light-green;

// ============================================
// TYPOGRAPHY
// ============================================

$npt-font-size:                  rem(16px);
$npt-font-size-secondary:        rem(14px);
$npt-icon-size:                  rem(32px);
$npt-icon-size-small:            rem(24px);
$npt-line-height:                1.4;

// ============================================
// SIZING & SPACING
// ============================================

// 40px - 2px for border on parent
$npt-height:                     rem(38px);

$npt-border-width:               rem(1px);
$npt-helper-text-spacer:         rem(4px);
$npt-border-radius:              rem(4px);
$npt-focus-box-shadow-spread:    rem(4px);

// vertical space between inputs
// should be visually 24px total, so estimating
// 10px here plus the little extra in the line
// height of the labels.
$npt-spacer:                     rem(10px);

// horizontal (internal) padding
$npt-padding:                    rem(12px);
$npt-textarea-min-height:        rem(100px);

$npt-background-image-size:      rem(24px);

// selection elements
$npt-selection-size-reg:         rem(24px);
$npt-selection-size-large:       rem(32px);
$npt-selection-label-spacer:     rem(8px);

$npt-toggle-width:               rem(80px);
$npt-toggle-height:              rem(32px);
$npt-toggle-border-radius:       rem(16px);
$npt-toggle-border-width:        rem(2px);
$npt-toggle-translate:           rem(50px);
$npt-toggle-label-offset:        rem(16px);
$npt-toggle-circle-size:         $npt-icon-size-small;
$npt-toggle-circle-offset:       rem(2px);
$npt-toggle-text-shadow:         0 rem(1px) rem(2px) rgba(0,0,0,0.1);
$npt-symbols-offset:             rem(10px);
$npt-toggle-small-scale-by:      .75;
