@import '../modules/all';

// FONT WEIGHTS
$light: 300;
$regular: 400;

// FONT FAMILIES
$sans: "Outfit", sans-serif;
$mono: "Courier", "Courier New", "Lucinda Console", monospace;

// BASE FONT SETTINGS
$base-font-family: $sans;
$base-font-weight: $light;
$base-letter-spacing: 0.02em; // computes to 0.32px for 16px font size
$root-font-size: 62.5%;
$base-font-size: 14px;
$base-line-height: 1.4;
$base-border-radius: rem(3px);

// LAYOUT
$header-height: rem(45px);
$header-height-medium: rem(70px);
$content-padding-medium: 1em;

// Z-INDEX
$loader-z: 900;
$header-z: 1000;
$panels-z: 800;
$float-z: 600;
$processing-z: 700;
$humane-z: 1100;
$popup-z: 3001; // must be higher than Rental Tools Nav sidenav z-index (3000)

// OTHER
$ease: cubic-bezier(0.5, 0, 0, 1);

// PREFIXER SETTINGS
$prefix-for-webkit:    true;
$prefix-for-mozilla:   true;
$prefix-for-microsoft: true;
$prefix-for-opera:     false;
$prefix-for-spec:      true;

// RESPONSIVE BREAKPOINTS
// $breakpoint-small and below (<768px): mobile. has hamburger menu, no sidebar
// $breakpoint-small to $breakpoint-medium (768px–1023px): tablet/small laptop. has hamburger menu, no sidebar
// $breakpoint-medium to $breakpoint-large (1024px–1439px): laptop. has sidebar menu
// $breakpoint-large and up (>=1440px): desktop and beyond 🚀. has sidebar menu

$breakpoint-small:  48em;  // 48 * 16 = 768
$breakpoint-medium: 64em;  // 64 * 16 = 1024
$breakpoint-large:  90em;  // 90 * 16 = 1440

// SPACERS
// used for padding & margin
$spacer-xxsmall:  rem(4px);
$spacer-xsmall:   rem(8px);
$spacer-small:    rem(16px);
$spacer-medium:   rem(24px);
$spacer-large:    rem(32px);
$spacer-xlarge:   rem(40px);
$spacer-xxlarge:  rem(48px);

// Native CSS Variables
:root {
  // Setting font size for Rental Tools Nav component
  --rt-nav-root-font-size: 1.6rem;
}
