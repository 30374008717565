/*
* All the colors of the CoStar rainbow
*/

// Mortar UX Palette Colors

$apts-green:      #77B800;
$avocado:         #427B01;
$black:           #000;
$bright-red:      #FF5C49;
$cerulean:        #189ED9;
$cerulean-dark:   #0576A7;
$charcoal:        #4C4C4C;
$crimson:         #E01600;
$forest-green:    #0B6839;
$goldenrod:       #FDB502;
$goldenrod-light: #FFF5D1;
$light-green:     #F2F9E9;
$light-gray:      #F5F5F5;
$medium-gray:     #BFBFBF;
$rose:            #FFF3F1;
$sky:             #DCF3FB;
$white:           #fff;

// Colors approved by design, but not yet in Mortar
$medium-light-gray: #DFDFDF;

// -----------------------



$light-line-color: $light-gray;
$medium-line-color: #DFDFDF;
$dark-line-color: $medium-gray;

$base-background-color: $white;
$card-header-background-color: #FAFAFA;
$muted-background-color: #F6F6F6;

// Link Colors

$link-color: $cerulean-dark;
$link-hover-color: $link-color;

// Text Colors

$base-text-color: $black;
$heading-color: $black;
$success-text-color: $avocado;
$warn-text-color: #F79000;
$alert-text-color: $crimson;
$text-muted-color: #000;

$header-color: $black;

// Additional UI Colors

$ui-primary-color: $apts-green;
$header-border-color: rgba($medium-gray, .7);

// Meow colors

$meow-background-success: $light-green;

// Button

$button-primary-color: $avocado;
$button-primary-text-color: $white;
$button-primary-hover-color: $forest-green;
$button-primary-hover-text-color: $white;
$button-secondary-color: $white;
$button-secondary-text-color: $avocado;
$button-secondary-border-color: $avocado;
$button-secondary-hover-color: $light-green;
$button-secondary-hover-text-color: $avocado;
$button-disabled-color: $medium-gray;
$button-disabled-text-color: $charcoal;

// Labels
$info-background-color: $sky;
$info-border-color: $cerulean-dark;
$warn-background-color: $goldenrod-light;
$warn-border-color: $goldenrod;
$alert-background-color: $rose;
$alert-border-color: $bright-red;

// Alert

$success-color: $avocado;
$success-light-color: rgba($success-color, 0.05);
$warn-color: #FF8700;
$warn-light-color: rgba($warn-color, 0.05);
$alert-color: #D11D00;
$alert-light-color: rgba($alert-color, 0.05);
$info-color: $base-text-color;
$info-light-color: $sky;

// Banner

$banner-success-color: $apts-green;
$banner-success-light-color: $light-green;
$banner-warn-color: $goldenrod;
$banner-warn-light-color: $goldenrod-light;
$banner-alert-color: $bright-red;
$banner-alert-light-color: $rose;
$banner-info-color: $cerulean-dark;


// Box

$box-border-color: $medium-line-color;
$box-background-color: $white;
$box-hover-color: $light-line-color;
$box-alternate-color: $box-hover-color;
$box-contrast-color: $box-hover-color;
$box-feature-color: $box-hover-color;
$box-highlight-color: $box-hover-color;
$box-important-color: $box-hover-color;
$box-disabled-background-color: $light-line-color;
$box-disabled-text-color: $box-border-color;

// Icon

$icon-primary-color: $button-primary-color;
$icon-muted-color: $box-disabled-background-color;
$icon-accent-color: $link-color;

