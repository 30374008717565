.show-for-print-only {
	display: none;
	visibility: hidden;
}

@media print {

	body, h1, h2, h3, h4, h5, h6 {
		color: #333 !important;
	}

	.text-center-for-print {
		text-align: center; }

	.text-right-for-print {
		text-align: right;
	}

	.show-for-print-only, .show-for-print {
		display: block !important;
		visibility: visible !important; }

	.hide-for-print {
		display: none !important; }

	table.show-for-print-only, table.show-for-print {
		display: table !important; }

	thead.show-for-print-only, thead.show-for-print {
		display: table-header-group !important; }

	tbody.show-for-print-only, tbody.show-for-print {
		display: table-row-group !important; }

	tr.show-for-print-only, tr.show-for-print {
		display: table-row !important; }

	td.show-for-print-only, td.show-for-print {
		display: table-cell !important; }

	th.show-for-print-only, th.show-for-print {
		display: table-cell !important; }


	.header {
		position: absolute;
	}

	.menupanel {
		display: none;
	}

	.site-wrapper,
	.view-container {
		@include display(block);
	}

	.full-width-for-print {
		width: 100% !important;
		max-width: none !important;
	}

	.bar-head {
		border-top-width: rem(2px);
	}

}
