@mixin secondary-bold {
  @extend .text-semibold;
  @extend .text-secondary;
}

.npt-radio-wrapper {
  @extend .npt-fieldset--selection; // in _mixins.scss

  label {
    display: inline-block;

    // If we use a template for the label, keep base font weight (200 at time of writing)
    // and it is up to implementer to style the template as they desire
    // Otherwise, if just a single-line label, set it to $regular (300 at time of writing)
    font-weight: $base-font-weight;
    color: $black;
    .label-standalone {
      font-weight: $regular;
      color: $black;
    }

    .label-for-template {
      @include secondary-bold;
      @extend .buffer-bottom-tiny;

      &--no-buffer {
        @include secondary-bold;
        @extend .text-secondary;
      }
    }

    &:before, &:after {
      border-radius: 100%;
    }

    // inner circle
    &:after {
      color: $black;
      content: '';
      width: 0.5 * $npt-selection-size-reg;
      height: 0.5 * $npt-selection-size-reg;
      left: 0.25 * $npt-selection-size-reg;
      top: 0.25 * $npt-selection-size-reg;
      background: $npt-selection-color;
      opacity: 0;
      @include npt-transition(opacity);
    }
  }

  // disabled styles
  &.npt-disabled,
  // when disabled because the user is offline and disabledOffline is true
  input[disabled] {
    cursor: not-allowed;
  }
  &.npt-disabled label,
  input[disabled] ~ label {
    cursor: not-allowed;
    // outer circle
    &:before {
      background-color: $npt-disabled-background-color;
      border-color: $button-disabled-color;
    }
    // inner circle
    &:after {
      background-color: $npt-disabled-color;
    }
  }
}

.npt-radio-wrapper--large {
  @extend .npt-radio-wrapper;
  @extend .npt-fieldset--selection-large;

  label {
    line-height: unset;
    .label-standalone {
      line-height: $npt-selection-size-large;
    }

    // inner circle
    &:after {
     width: 0.5 * $npt-selection-size-large;
     height: 0.5 * $npt-selection-size-large;
     left: 0.25 * $npt-selection-size-large;
     top: 0.25 * $npt-selection-size-large;
    }
  }
}

.npt-radio-wrapper--card {
  @extend .npt-radio-wrapper;

  input {
    width: 100%;

    &:checked ~ label {
      border-color: $npt-focus-border-color;
    }
    &:focus ~ label {
      border-color: $npt-focus-border-color;
      @include npt-box-shadow($npt-focus-box-shadow-color);
    }
  }

  label {
    @include npt-transition;
    padding: $spacer-small $spacer-small $spacer-small ($spacer-small * 3);
    border-radius: $npt-border-radius;
    width: 100%;
    border: 1px solid $npt-border-color;

    // outer circle
    &:before {
      left: $spacer-small;
    }

    // inner circle
    &:after {
      // todo sometime - figure out the real math using variables
      top: rem(22px);
      left: rem(22px);
    }

    &:hover {
      border-color: $npt-selection-hover-color;
    }
  }

  // offline/disabled styles
  &.npt-disabled label,
  input[disabled] ~ label {
    background: $npt-disabled-background-color;
    &:hover {
      border-color: $npt-border-color;
    }
  }
}
