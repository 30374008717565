@mixin flex-header {
	@include display(flex);
	@include flex-wrap(nowrap);
	@include align-items(center);
	@include justify-content(space-between);
}

.page-header {
	background-color: $white;
	padding: $spacer-medium 0 $spacer-small;

	@media only screen and (min-width: $breakpoint-medium) {
		padding: $spacer-large 0;
	}

	p {
		margin: 0.25em 0 0;
		line-height: 1.25;
	}
}

.page-header--no-border {
	@extend .page-header;
}

.page-header--with-border {
	@extend .page-header;
	border-bottom: rem(1px) solid $header-border-color;
	margin-bottom: $spacer-small;

	@media only screen and (min-width: $breakpoint-small) {
		margin-bottom: $spacer-medium;
	}
}

// Use if you need to zero-out the standard margin-bottom
.offset-header-margin {
	margin-top: -$spacer-medium;
}

a.header-back-link {
	@include icon-before('.backMortarIcon');
	display: inline-block;
	margin-bottom: $spacer-small;

	@media only screen and (min-width: $breakpoint-medium) {
		margin-bottom: $spacer-medium;
	}

	&:hover,
	&:focus {
		text-decoration: none;
		span {
			text-decoration: underline;
		}
	}
}

.header-title-wrapper {
	&.with-inline-button {
		@include flex-header;
	}
	&.with-button {
		@media only screen and (min-width: $breakpoint-small) {
			@include flex-header;
		}
	}
}

// h1 and supporting text
.header-title {
	width: 100%;
	h1 {
		font-weight: $regular;
		color: $heading-color;
		font-size: rem(20px);
		line-height: 1.25;
		padding: 0;
		margin-bottom: 0;

		@media only screen and (min-width: $breakpoint-small) {
			font-size: rem(30px);
		}
	}

	&--with-supporting {
		h1 {
			margin-bottom: $spacer-xxsmall;
			@media only screen and (min-width: $breakpoint-small) {
				margin-bottom: $spacer-xsmall;
			}
		}
	}
}

// <p> underneath the <h1>
.header-supporting-text {
	// on mobile, if there are multiple
	// spans within the supporting text,
	// make them block with space between.
	>span:not(:only-child) {
		display: block;
		&:not(:last-child) {
			margin-bottom: .5em;
		}
		@media only screen and (min-width: $breakpoint-small) {
			display: inline-block;
			margin-bottom: 0;
		}
	}
}

// button or link to the right of the <h1>
// can either:
// - be inline for all views (inlineAllViews param is true)
// - flow with content in mobile, then inline for tablet+ (inlineAllViews param is false)
.header-action {
	display: block;
	@extend .no-wrap;
	align-self: flex-start;

	.help-link {
		font-size: rem(16px);
		display: block;
		margin-left: 0;
		@include icon-before('.supportMortarIcon');

		line-height: rem(25px); // match h1 line-height
		@media only screen and (min-width: $breakpoint-small) {
			line-height: rem(37.5px);
			margin-left: 1em;
		}
	}

	.with-inline-button & {
		margin: 0 0 0 1em;
		text-align: right;
	}

	@media only screen and (min-width: $breakpoint-small) {
		margin: 0 0 0 1em;
		text-align: right;
	}
}

