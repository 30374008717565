// Input of type "file"
input[type="file"] {
  @extend .text-base;
  cursor: pointer;
  border: $npt-border-width solid transparent;
  border-radius: $npt-border-radius;
  &:focus {
    outline: none;
    border: $npt-border-width solid $npt-focus-border-color;
    @include npt-box-shadow($npt-focus-box-shadow-color);
  }
}

input::file-selector-button {
  @extend .btn;
  @extend .btn-medium;

  font-family: $base-font-family;
  margin-right: $npt-spacer; // this only works in some browsers
}

.uploaded-image-preview {
  img {
    max-height: rem(500px);
  }
}