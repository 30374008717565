.renters-insurance-policy-table {
  &, &.tbody, &.tr {
    width: 100%;
  }
  tr {
    padding-top: .5em;
    padding-bottom: .5em;
    display: block;
  }
  th, td {
    display: block;
    text-align: left;
  }
  td {
    // Allow very long words to break line if needed (super edge case)
    word-wrap: break-word;
    word-break: break-all;
  }
  span {
    display: block;
  }
  @media screen and (min-width: $breakpoint-small) {
    tr {
      display: flex;
      padding-top: 1em;
      padding-bottom: 1em;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
    }
    tr:last-child {
      border-bottom: none;
    }
    th {
      flex: 1 0 auto; // Prevent label text from breaking line
      padding-right: 1em; // Give space between label and value if they get too close
    }
    td {
      text-align: right;
    }
  }
}
