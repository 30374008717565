.npt-fieldset--checkbox {
  @extend .npt-fieldset--selection; // in _mixins.scss

  label {
    &:before {
      border-radius: $npt-border-radius;
    }
    // checkmark
    &:after {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: $npt-selection-size-reg;
      line-height: $npt-selection-size-reg;
      text-align: center;

      font-family: 'MortarIcons';
      content: "\e913";
      font-size: $npt-selection-size-reg;

      color: $npt-selection-color;
      opacity: 0;
      @include npt-transition(opacity);
    }
  }

  &.npt-disabled {
    label:after {
      color: $npt-disabled-color; // checkmark
    }
  }
}

.npt-fieldset--checkbox-large {
  @extend .npt-fieldset--checkbox;
  @extend .npt-fieldset--selection-large;
  label {
    &:after {
      font-size: $npt-selection-size-large;
      line-height: $npt-selection-size-large;
    }
  }
}

// indeterminate state
.npt-checkbox--partial {
  label {
    &:after {
      content: "\e94a"; // minusStoryicon
    }
  }
}

