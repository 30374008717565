// bourbon transition mixin fix: https://github.com/thoughtbot/bourbon/issues/794#issuecomment-152150112
@mixin trans-prefix($value, $prefix: "") {
	$slice: str-slice(inspect($value), 0, 9);
	@if $slice == "transform" {
		#{$prefix}transition: #{$prefix}#{$value};
	} @else {
		#{$prefix}transition: $value;
	}
}
@mixin transition($properties...) {
	$spec: ();
	@if length($properties) > 1 {
		@for $i from 1 through length($properties) {
			$spec: append($spec, nth($properties, $i), comma);
		}
	} @else {
		$spec: $properties;
	}
	@include trans-prefix($spec, -webkit-);
	@include trans-prefix($spec, -moz-);
	@include trans-prefix($spec);
}
