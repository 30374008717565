// Note: We are currently not using the "small" variation of the Tabs component in Mortar

.section-nav-wrapper {
	overflow: hidden;
	position: relative;
	padding-right: rem(10px);
	max-width: 100%;
	height: rem(60px);

	// re-align the element to the left.
	// see padding on .section-nav li:first-child
	margin-left: rem(-5px);

	// blur along the right side to help users
	// know that there's more to see when the list
	// overflows and they can scroll
	&:after {
		content: '';
		position: absolute;
		z-index: 1;
		height: 100%;
		width: rem(50px);
		top: 0;
		right: 0;
		pointer-events: none;
		background: linear-gradient(to left, $white, rgba($white, 0));
	}

	@media only screen and (min-width: $breakpoint-medium) {
		height: rem(70px);
	}
}

.section-nav {
	overflow-x: auto;
	display: flex;
	width: 100%;
	position: absolute;
	bottom: 0;

	// allow room for the blur when overflowing
	// so it doesn't always overlap the last tab
	padding-right: rem(30px);

	// Give a few pixels of padding to allow for the
	// focus outline to show, since we are hiding overflow
	// in order to allow scrolling when the tab list is too long.
		padding-left: rem(5px);

	// WebKit allows us to remove the scrollbar, otherwise degrade gracefully (show the scrollbar).
	// Mobile devices generally have a much thinner scrollbar so we're OK with that as we
	// expect the section-nav to get cut off moreso on mobile devices than desktop.
	&::-webkit-scrollbar {
		display: none;
	}
}

a.section-nav-tab {
	cursor: pointer;
	font-weight: inherit;
	text-decoration: none;
	flex-shrink: 0;
	color: $black;
	display: block;
	font-weight: $regular;
	font-size: rem(18px);
	line-height: 1.9em;
	padding: 0 rem(6px);
	margin-top: rem(4px);
	margin-bottom: rem(5px);
	margin-right: rem(13px);
	border-radius: rem(4px);
	border: rem(1px) solid transparent;

	&:hover,
	&:focus {
		text-decoration: none;
		outline: none;
		color: $black;
	}
	&:focus {
		@include focus-box-shadow($apts-green);
		border-color: $avocado;
		outline: none;
	}
}

.section-nav-skater {
	height: rem(4px);
	width: 0px;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: $success-text-color;
	will-change: transform;
	@include transform-origin(left center);
	@include transition(width 0.3s $ease-out-cubic, transform 0.3s $ease-out-cubic);
}

