/**
  * @name UserNav
  * @description Legacy styling for user nav menu.
  */


.trigger {
	color: $base-text-color;
	font-size: 1.4em;
}

.user-nav-wrapper {
	height: 100%;
	padding-left: 1em;
}

@media only screen and (min-width: $breakpoint-medium) {
	// disable header control shim stuff for desktop
	.header {
		position: absolute;
	}
	.header-hide {
		visibility: visible;
		@include transform(none);
	}

}


/**
*
* MENU TRIGGER
*
**/

.menu-trigger-wrapper {
	@include order(3);
}
.btn-menu-trigger {
	font-size: 0.9em;
	position: relative;
	display: inline-block;
	width: 2em;
	height: 2em;
	cursor: pointer;
}

.btn-menu-trigger span {
	position: absolute;
	top: 50%;
	left: 0;
	display: block;
	width: 100%;
	height: rem(3px);
	border-radius: rem(2px);
	margin-top: -0.1em;
	background-color: $box-background-color;
	@include transition(background $ease 0s 0.3s);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.btn-menu-trigger span:before,
.btn-menu-trigger span:after {
	position: absolute;
	left: 0;
	border-radius: rem(2px);
	width: 80%;
	height: 100%;
	background-color: $box-background-color;
	content: '';
	@include transition(top $ease 0.2s 0.3s, transform $ease 0.3s);
	@include transform(translate3d(0, 0, 0));
	-webkit-backface-visibility: hidden;
}

.btn-menu-trigger span:before {
	top: -0.5em;
}

.btn-menu-trigger span:after {
	top: 0.5em;
}

.btn-menu-trigger.btn-menu-open span,
.menupanel-open .btn-menu-trigger span {
	background-color: transparent;
	@include transition(background $ease 0s 0.2s);
}

.btn-menu-trigger.btn-menu-open span:before,
.menupanel-open .btn-menu-trigger span:before,
.btn-menu-trigger.btn-menu-open span:after,
.menupanel-open .btn-menu-trigger span:after {
	background-color: $box-background-color;
	top: 0;
	width: 100%;
	@include transition(top $ease 0.2s, transform $ease 0.3s 0.2s);
}

.btn-menu-trigger.btn-menu-open span:before,
.menupanel-open .btn-menu-trigger span:before {
	@include transform(rotate(-45deg));
}

.btn-menu-trigger.btn-menu-open span:after,
.menupanel-open .btn-menu-trigger span:after {
	@include transform(rotate(45deg));
}

@media only screen and (min-width: $breakpoint-medium) {

	.menu-trigger-wrapper {
		display: none;
		visibility: hidden;
	}

}

