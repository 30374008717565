/** TYPE
---------------------------------------- **/

@mixin icon-before($icon-class) {
	&:before {
		@extend .mortar-icon;
		@extend #{$icon-class};
		margin-right: 0.25em;
	}
	&:hover,
		&:focus {
			text-decoration: none;
			span {
				text-decoration: underline;
			}
		}
}

// Set base font-sizes and fonts. Set base font-size to 10px.
html {
	font-size: $root-font-size;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, button, input, select, textarea {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
}

body {
	font-size: rem($base-font-size);
	line-height: $base-line-height;
	color: $base-text-color;
}


/** Paragraph and list margins **/

p,
ul,
ol,
dl {
	margin: 0 0 1em 0;
	line-height: 1.5em;
	font-size: rem(16px);
	&:last-child {
		margin-bottom: 0;
	}
}

.pre-wrap {
	white-space: pre-wrap;
	word-break: break-word;
}

.no-wrap {
	white-space: nowrap;
}

ul {
	padding-left: 1em;
}

ul.list-buffer li,
ol.list-buffer li,
menu.list-buffer li,
dl.list-buffer dd {
	margin-bottom: 0.5em;
}

dl.inline-term dt {
	float: left;
	margin-right: 0.5em;
	margin-bottom: 0.5em;
}

dl dd {
	@extend .clearfix;
	margin: 0;
}

.unstyled-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

abbr[title] {
	cursor: help;
	text-decoration: none;
}

.inline-list li {
	display: inline-block;
}

.list-checked {
	list-style-type: none;
	padding-left: 0;

	li {
		position: relative;
		padding-left: 1.5em;
		margin-bottom: .3em;

		&:before {
			@extend .mortar-icon;
			@extend .checkMortarIcon;
			color: $success-color;
			font-size: 1.2em;
			position: absolute;
			left: 0;
			top: .1em;
		}
	}
}

.flex-list {
	> li {
		@include flexbox;
		@include justify-content(space-between);
	}
}

/** Other text tags **/

sup {
	font-size: 0.5em;
	position: relative;
	top: -0.7em;
	margin: 0 0.2em 0 0;
}

/** Base link styling **/

a,
.style-like-link {
	font-size: inherit;
	text-decoration: none;
	color: $link-color;
	font-weight: $regular;
	@include transition(color 0.2s $ease);
	> * {
		vertical-align: baseline !important;
	}

	// prevent icons from becoming underlined on hover
	[class^="icon-"],
	[class*=" icon-"],
	[class*="mortarIcon"] {
		@include transform(translate(0, 0));
	}

	.no-touchevents &:hover,
	.no-touchevents &:focus {
		color: $link-hover-color;
		text-decoration: underline;
	}

	// add any exclusions to underline on hover/focus here
	[class^="icon-"],
	[class*=" icon-"],
	[class*="mortarIcon"],
	&.npt-extra,
	.ledger-action-icon,
	.datepicker-anchor & {
		@extend .link-nounderline;
	}

	// don't underline icons within links
	&.has-icon-within {
		&:hover, &:focus {
			@extend .link-nounderline;
			* {
				text-decoration: underline;
			}
			i {
				@extend .link-nounderline;
			}
		}
	}

	&.disabled {
		cursor: not-allowed;
		opacity: 0.5;

		&:hover {
			color: inherit;
		}
	}
}

.nudge-down {
	display: inline-block;
	transform: translateY(0.2em);
}

// if you want links with href=# to have outlines,
// give them the class of .focus-outline
a[href="#"] {
	&:focus {
		outline: none;
	}
}

%focus-outline {
	&:focus {
		outline: thin dotted;
	}
}

a.focus-outline,
button.style-like-link,
button.focus-outline,
a[href="#"].focus-outline {
	@extend %focus-outline;
}

.ledger-icon {
	font-size: $mortar-icon-size-relative-to-root;
	padding-right: 0;
	@media only screen and (min-width: $breakpoint-small) {
		padding-right: rem(14px);
	}
}
.ledger-icon--collapse {
	@extend .ledger-icon;
}
.ledger-icon--edit {
	@extend .ledger-icon;
	@extend .mortar-icon;
	@extend .editMortarIcon;
	color: $base-text-color;
}
.ledger-icon--warn {
	@extend .ledger-icon;
	@extend .mortar-icon;
	@extend .triangleMortarIcon;
	color: $warn-text-color;
	font-size: rem(28px);
}

.link-unstyled {
	cursor: pointer;
	font-weight: inherit;
	text-decoration: none !important;
	color: $base-text-color !important;
}

a.link-unstyled-ledger {
	@extend .link-unstyled;
	@include transition(color 0.3s $ease);
	color: $black !important;
	&:hover {
		color: $link-hover-color !important;
	}

	// styles to make focusing look okay
	@extend %focus-outline;
	display: block;
	// to offset row border
	margin-bottom: rem(1px);
	padding-left: $spacer-small;
	padding-right: $spacer-small;
	margin-left: $spacer-small * -1;
	margin-right: $spacer-small * -1;
	@media only screen and (min-width: $breakpoint-small) {
		padding-left: $spacer-medium;
		padding-right: $spacer-medium;
		margin-left: $spacer-medium * -1;
		margin-right: $spacer-medium * -1;
	}
}

.link-nounderline {
	text-decoration: none !important;
}

.link-alert {
	color: $alert-color;

	&:hover, &:focus {
		color: $alert-color !important;
	}
}


.link-emphasis {
	color: $base-text-color;

	.no-touchevents &:hover, .no-touchevents & > *:hover {
		color: $link-hover-color;
	}
}

/** headers **/

.h-global {
	margin: 0;
	margin-bottom: em(5px, $em-base);
	font-weight: $regular;
	line-height: 1.4;
}

h1, .h-headline, .h-one {
	@extend .h-global;
	font-size: rem(30px);
}

h2, .h-subheadline, .h-two {
	@extend .h-global;
	font-size: rem(22px);
}

h3, .h-three {
	@extend .h-global;
	font-size: rem(20px);
}

h4, .h-standfirst, .h-four {
	@extend .h-global;
	font-size: rem(18px);
}

h5, .h-related, .h-five {
	@extend .h-global;
	margin-bottom: 0;
	padding-bottom: 0;
	font-size: rem(16px);
}

h6, .h-promo, .h-6 {
	@extend .h-global;
	font-size: rem(11px);
}

.h-byline {
	@extend .h-global;
	font-size: rem(15px);
}

.intro-text {
	color: $base-text-color;
	font-weight: $light;
	font-size: rem(16px);
	line-height: 1.375;
	margin-bottom: em(20px, $em-base);
}

/** type helper classes **/

.uppercase {text-transform: uppercase; }
.capitalize { text-transform: capitalize;}

.text-big {
	font-size: rem(18px);
	line-height: 1.5em;
}

.text-base {
	font-size: rem(16px);
}

.text-medium {
	font-size: rem(14px);
}

small, .text-small {
	font-size: rem(12px);
	line-height: 1.4;
}

.text-break {
	white-space: pre-wrap;
	&:before {
		content: '\A';
	}
}

.text-center {
	text-align: center;
}

// useful for aligning varying sized text
.text-height-default {
	line-height: 1;
}
.text-height-small {
	line-height: rem(14px);
}
.text-height-medium {
	line-height: 1.4;
	@media only screen and (min-width: $breakpoint-small) {
		line-height: rem(20px);
	}
}

.text-center-for-small-only {
	text-align: center;

	@media only screen and (min-width: $breakpoint-small) {
		text-align: inherit;
	}
}

.text-center-for-medium-up {
	@media only screen and (min-width: $breakpoint-small) {
		text-align: center;
	}
}

.text-right {
	text-align: right;
}

.text-right-margin {
	margin: 0 0 0 auto;
}

.text-right-for-medium-up {
	@media only screen and (min-width: $breakpoint-small) {
		text-align: right;
	}
}

.text-right-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		text-align: right;
	}
}

.text-left {
	text-align: left;
}

.text-light {
	font-weight: $light;
}

.text-normal,
.text-emphasis,
strong,
.text-semibold {
	font-weight: $regular;
}

// legacy - scared to remove
strong, .text-semibold {
	font-size: rem(16px);
}

.text-strikethrough {
	text-decoration: line-through;
}

.text-currency {
	&:before {
		content: '$'
	}
}

// Use truncate-container to restrict the width of a white-space: nowrap element
// http://stackoverflow.com/questions/17126721/how-to-deny-nowrap-to-increase-width-of-element
.truncate-container {
	display: table;
	table-layout: fixed;
}
.text-truncate {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-nowrap {
	white-space: nowrap;
}

.text-comma-separated-list {
	> * {
		display: inline-block;
		&:not(:last-child):after {
			content: ', ';
		}
	}
}

sub {
	vertical-align: baseline;
}

/** empty states **/
.empty {
	color: rgba($base-text-color, .6);
}

/** Alert text and banners **/

.text-default { color: $base-text-color !important; }
.text-alert { color: $alert-text-color !important; }
.text-caution { color: $warn-text-color !important; }
.text-warn { color: $warn-text-color !important; }
.text-info { color: $info-color !important; }
.text-success { color: $success-text-color !important; }

// These were changed to $black per design when we rolled
// out the font update to Outfit. At a later time we can
// comb through all the uses of these classes and remove
// the ones that are not needed or update them to .text-default
.text-muted { color: $black !important; }
.text-secondary { color: $black !important; }

$banner-wrapper-margin: 2rem;
$banner-wrapper-margin-small: 1rem;

.banner-wrapper {
	overflow: hidden;
	position: relative;
	max-height: rem(400px);
	margin-top: $banner-wrapper-margin;
	margin-bottom: $banner-wrapper-margin;
	[class^="banner-"] {
		margin: 0;
	}
}

.banner-include-transitions {
	@include transition (all .1s $ease);
	[class^="banner-"] {
		@include transition (all .1s $ease 0.1s);
	}
}

.banner-wrapper-buffer-top {
	margin-top: $banner-wrapper-margin;
	margin-bottom: 0;
}
.banner-wrapper-buffer-bottom {
	margin-top: 0;
	margin-bottom: $banner-wrapper-margin;
}
.banner-wrapper-buffer-small {
	margin-top: $banner-wrapper-margin-small;
	margin-bottom: $banner-wrapper-margin-small;
}
.banner-wrapper-buffer-top-small {
	margin-top: $banner-wrapper-margin-small;
	margin-bottom: 0;
}
.banner-wrapper-buffer-bottom-small {
	margin-top: 0;
	margin-bottom: $banner-wrapper-margin-small;
}
.banner-wrapper-buffer-none {
	margin-top: 0;
	margin-bottom: 0;
}
.banner-wrapper-hide {
	max-height: 0;
	padding: 0;
	margin: 0;
	[class^="banner-"] {
		@include transform(scale(0));
	}
}
.banner-wrapper-wide {
	margin-left: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
}

@mixin banner-link($color) {
	a,
	a:visited,
	.style-like-link {
		@extend .focus-outline;
		color: $color;
		text-decoration: underline;
		font-weight: $regular;
	}
	a:hover,
	a:focus,
	.style-like-link:hover,
	.style-like-link:focus {
		color: $color;
	}
}

.banner- {
	font-size: rem(16px);
	line-height: 1.5;
	border-radius: rem(3px);
	color: $base-text-color;
	@include banner-link($base-text-color);
	padding: $spacer-small;
	display: block;
	margin: 1.5em auto;
	text-align: left;
}

.banner-fullwidth {
	width: 100%;
}

.banner-inner {
	display: flex;
}

.banner-icon {
	padding-right: 0.3em;
	font-size: 1.8em;
}

.banner-title {
	font-size: rem(18px);
	color: $black;
	padding-bottom: .2em;
	display: block;
}

.banner-close {
	@extend .popup-close;
	padding: .5em;
	text-decoration: none !important;
}

.banner-info {
	@extend .banner-;
	background-color: $info-light-color;
	border: rem(1px) solid $banner-info-color;
}

.banner-warn {
	@extend .banner-;
	background-color: $banner-warn-light-color;
	border: rem(1px) solid $banner-warn-color;
}

.banner-alert {
	@extend .banner-;
	background-color: $banner-alert-light-color;
	border: rem(1px) solid $banner-alert-color;
}

.banner-success {
	@extend .banner-;
	background-color: $banner-success-light-color;
	border: rem(1px) solid $banner-success-color;
}

.banner-neutral {
	@extend .banner-;
	background-color: $light-gray;
	border: rem(1px) solid $medium-gray;
}

.banner-legal {
	@extend .banner-;
	font-size: rem(15px);
	background-color: $light-gray;
	border: rem(1px) solid $light-gray;
}

.banner-block {
	width: 100%;
	border-radius: 0;
	margin: 0;
}

.banner-compact {
	@extend .banner-;
	padding: $spacer-xsmall $spacer-small;
}

.banner-dismissible > div {
	padding-right: 3em;
}

// broken out separate from label to differentiate from account labels
%badge-defaults {
	font-weight: $regular;
	font-size: rem(12px);
	line-height: rem(22px);
	text-align: center;
	padding: 0 rem(9px);
	text-transform: uppercase;
	border-radius: rem(11px);
	color: $black;
	display: inline-block;
	white-space: nowrap;
}

.badge-info, .badge.info {
	@extend %badge-defaults;
	border: rem(1px) solid $cerulean;
}

.badge-warn, .badge.warn {
	@extend %badge-defaults;
	border: rem(1px) solid $warn-border-color;
}

.badge-alert, .badge.alert {
	@extend %badge-defaults;
	border: rem(1px) solid $alert-border-color;
}

.badge-neutral, .badge.neutral {
	@extend %badge-defaults;
	border: rem(1px) solid $medium-gray;
}

.badge-success, .badge.success {
	@extend %badge-defaults;
	border: rem(1px) solid $apts-green;
}

.illustration-paragraph {
	display: flex;
	text-align: left;

	> div:first-child {
		min-width: rem(32px);
		width: rem(32px);
		padding-top: rem(5px);
		margin-right: 1.2em;
	}
}

// Icon Modifiers
.icon-big {
	font-size: 1.8em;
}
.icon-huge {
	font-size: 2.5em;
}
.icon-styleguide {
	font-size: rem(50px);
}
.icon-link { color: $icon-accent-color !important; }

// special links
a.add-credit-link {
	@extend .no-wrap;
	@include icon-before('.rentSpecialMortarIcon');
}
a.record-payment-link {
	@extend .no-wrap;
	@include icon-before('.editMortarIcon');
}

.help-link {
	@extend .has-icon-within;
	@extend .focus-outline;
	@include icon-before('.supportMortarIcon');
}