@import './variables';
@import './mixins';

@import './checkbox';
@import './datepicker';
@import './input';
@import './otp-card';
@import './radio';
@import './radio-bar';
@import './upload';

legend {
  padding-inline-start: 0;
  padding-inline-end: 0;
}