/***************************************************
	LAYOUT
***************************************************/


/** ROOT LAYOUT
---------------------------------------- **/

// Set html/body max width
html {
	background-color: $base-background-color;
	margin: 0;
	padding: 0;
	width: 100%;
	display: flex;
}

body {
	margin: 0;
	padding: 0;
	flex: 1;
}

body.standalone-app {
	padding-top: 45px;
	@media only screen and (min-width: $breakpoint-small) {
		padding-top: 40px;
	}
	@media only screen and (min-width: $breakpoint-medium) {
		padding-left: 250px;
	}
}

body.site-loading, body.site-processing {
	overflow: hidden;
}

// Offscreen element absolutely-positioned
// way offscreen vertically.
// In router.js showCallback method, we will
// scroll to this element to ensure we always
// land users on the top of the page after navigating.
#scroll-helper {
	position: absolute;
	top: -10000px;
	left: auto;
	opacity: 0;
	width: 0;
	height: 0;
}

// Help Scout Beacon
// z-index must be higher than Rental Tools Nav
#beacon-container {
	position: relative;
	z-index: 1200;
}

// root wrapper
.site-wrapper {
	@include display(flex);
	@extend .clearfix;
	min-height: 100%;
	background-color: $white;
}

.menupanel, .view-container {
	@include flex(1 0);
}

.auth-container .site-wrapper {
	max-width: none !important;
}

.auth-view-container {
	margin: auto;
	padding-bottom: 3.55em;

	@media only screen and (min-width: $breakpoint-small) {
		margin-top: 1.5rem;
	}
}

.view-container {
	@include order(1);
	@include display(flex);
	@include flex-direction(column);
	@include align-items(stretch);

	position: relative;
}

.scrollable-content {
	// Seems excessive but is to ensure
	// no content is ever covered by
	// the Help Scout Beacon
	padding-bottom: rem(120px);

	background-color: $base-background-color;
	margin: 0 auto rem(-1px);
	@include flex(1 0 auto);
	width: 100%;
}

.wrapper {
	width: 100%;
}

@media only screen and (min-width: $breakpoint-medium) {
	.site-wrapper {
		@include flex-flow(row wrap);
	}

	.wrapper {
		width: 100%;
	}

	.menupanel {
		position: static;
		@include order(1);
		@include flex(none);
	}
	.view-container {
		@include align-self(stretch);
		@include order(2);
	}
}

.primary-column {
	@extend .small-12;
	@extend .medium-8;
	@extend .columns;
}

.secondary-column {
	@extend .small-12;
	@extend .medium-4;
	@extend .columns;
}

.primary-column--large-only {
	@extend .small-12;
	@extend .large-8;
	@extend .columns;
}
.secondary-column--large-only {
	@extend .small-12;
	@extend .large-4;
	@extend .columns;
}


/** SINGLE COLUMN
---------------------------------------- **/
.footer-links, .footer-colophon {
	padding-top: 1em;
	text-align: center;
}
.footer {
	margin-top: 6rem;
	border-top: rem(1px) solid $dark-line-color;
}

@media only screen and (min-width: $breakpoint-small) {
	.footer-links { text-align: left; }
	.footer-colophon { text-align: right; }
}
@media only screen and (min-width: $breakpoint-medium) {
	.single-column .site-wrapper.wrapper{
		padding-left: 0;
	}
}

/** TWO COLUMN CONTENT
---------------------------------------- **/
.two-columns {
	@include columns(2);
	@include column-gap(3rem);
}

@media only screen and (min-width: $breakpoint-medium) {
	.two-columns-for-medium-up {
		@include columns(2);
		@include column-gap(3rem);
	}
}

/** Flows
---------------------------------------- **/
.flow-columns {
	@extend .columns;
	@extend .small-12;
	@extend .medium-10;
	@extend .large-8;
	@extend .medium-offset-1;
	@extend .large-offset-2;
}

/** Flexbox - add classes as you need them
---------------------------------------- **/
.flex-parent {
	display: flex;
}

.flex-parent-for-medium-up {
	@media only screen and (min-width: $breakpoint-medium) {
		display: flex;
	}
}

.reverse-column-direction-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		flex-direction: column-reverse;
	}
}

.column-direction-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		flex-direction: column;
	}
}

.justify-children-end {
	justify-content: flex-end;
}

.justify-children-space-between {
	justify-content: space-between;
}

.align-children-start {
	align-items: flex-start;
}

.flex-1 {
	flex: 1;
}

/** CSS Grid - add classes as you need them
---------------------------------------- **/
@mixin grid-even-columns {
	display: grid;
	grid-auto-columns: minmax(0, 1fr);
	grid-auto-flow: column;
}

.grid-even-columns--medium-up {
	@media only screen and (min-width: $breakpoint-small) {
		@include grid-even-columns;
	}
}
