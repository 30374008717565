/***************************************************
	EMPTY STATE
***************************************************/

.empty-state {
	padding-top: 2.5em;
	padding-bottom: 3.5em;
	font-size: rem(16px);
	@extend .gutter;
	text-align: center;

	h2 {
		font-size: rem(20px);

		@media only screen and (min-width: $breakpoint-small) {
			font-size: rem(30px);
		}
	}

	h3 {
		font-size: rem(18px);

		@media only screen and (min-width: $breakpoint-small) {
			font-size: rem(20px);
		}
	}

	.learn-more {
		@extend .columns;

		hr {
			@extend .buffer-large;
		}
	}
}

.empty-state-icon {
	width: rem(70px);

	@media only screen and (min-width: $breakpoint-small) {
		width: rem(100px);
	}
}
