/***************************************************
	GLOBAL PANEL STUFF
***************************************************/


/**
  * @name Slide Up panel
  * @description Core style for slide up panel and interaction.
  * @markup
  *  <div class="panel">
  *      <section class="panel-content-container scrollable">
  *          <div class="scrollable-content">
  *              // content here
  *          </div>
  *      </section>
  *  </div>
  */
.panel {
	position: absolute;
	right: 1%;
	top: $header-height;
	visibility: hidden;
	z-index: $panels-z;
	@include transition(transform 0.2s $ease);
	@include transform(translate3d(0, -101%, 0)); // 3d rather than Y to improve performace
	@include display(flex);
	@include flex-direction(column);
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;

	border: rem(1px) solid $box-border-color;
	box-shadow: 0 rem(2px) rem(5px) rgba(0,0,0,0.2);
	border-radius: $base-border-radius;

	@media only screen and (min-width: $breakpoint-medium) {
		top: $header-height-medium;
		padding-top: 0;
	}
}

.panel-fullwidth {
	@extend .panel;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding-top: $header-height;

	@media only screen and (min-width: $breakpoint-medium) {
		top: $header-height-medium;
		overflow: visible;
		padding-top: 0;
		position: fixed;
		width: 30em;
		height: 30em;
		@include transform(translate3d(0, -101%, 0));
	}
}

.panel-content-container {
	width: 100%;
	height: inherit;
	background-color: $white;
	border-top: 0px solid $box-border-color;
	overflow: auto;
}

.site-container[class*="-open"] {
	overflow: hidden;
}
.panel-open {
	visibility: visible;
	@include transform(translate3d(0, 0, 0));
}
.panel-visible {
	visibility: visible;
}

.panel-header {
	width: 100%;
	background-color: $box-highlight-color;
	border-bottom: rem(1px) solid $box-border-color;
	padding: 0.9em 0;
}

.caret {
	@include transition(all 0.1s $ease);
	@include transform(scaleY(0));
	@include transform-origin(center bottom);
	$caret-size: rem(8px);
	content: '';
	width: 0;
	height: 0;
	display: block;
	position: absolute;
	margin-right: -#{$caret-size};
	border: $caret-size solid transparent;
	border-bottom-color: $box-border-color;
	z-index: $header-z + 2;
	bottom: rem(-2px);
}

.caret-open {
	@include transition-delay(0.1s);
	@include transform(scaleY(1));
}

.has-caret:after{
	@extend .caret;
}

@media only screen and (min-width: $breakpoint-medium) {

	.site-container[class*="-open"] {
		overflow: visible;
	}

	.notify-trigger-wrapper:after {
		right: 62%;
	}
}


/***************************************************
	NOTIFICATIONS PANEL
***************************************************/

/**
  * @name Notify-Panel
  * @description Notifications panel styles.
  * @markup
  *   <div class="panel notify-panel">
  *     <section class="panel-content-container scrollable">
  *       <div class="scrollable-content">
  *         // notify-items here
  *       </div>
  *     </section>
  *   </div>
  */

/** notifications toggle class, applied to site container **/
.notify-open .notify-panel {
	@extend .panel-open;
}
@media only screen and (min-width: $breakpoint-medium) {
	.notify-panel {
		right: 1%;
		left: auto;
	}
}

.notify-open .notify-trigger-wrapper:after {
	@extend .caret-open;
	display: none; // do not show notify caret on mobile

	@media only screen and (min-width: $breakpoint-medium) {
		display: block;
	}
}

.notify-item {
	@extend .clearfix;
	position: relative;
	border-bottom: rem(1px) solid $box-border-color;
	display: block;
}
.notify-header {
	&:empty {
		display: none;
	}
}

.notify-btn-dismiss {
	position: absolute;
	top: 0.3em;
	right: 0.2em;
}
