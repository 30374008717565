.img-fullwidth {
	width: 100%;
	display: block;
}

.img-halfwidth-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		height: rem(80px);
	}
}

.img-circle {
	display: inline-block;
	border-radius: 50%;
	border: rem(1px) solid $medium-line-color;
}

.provider-logo-small {
	height: rem(22px);
}

.provider-logo-medium {
	height: rem(30px);
}

.payment-card-icon {
	height: rem(32px);
	width: rem(46px);
	&--small {
		width: rem(32px);
	}
	&--small-with-margin {
		@extend .payment-card-icon--small;
		margin-right: $spacer-xsmall;
		vertical-align: sub;
	}
}

.visa {
	background-image: url("/img/card-icons/visa.svg");
	display: block;
	height: rem(10px);
	width: rem(10px);
}

// Icon displayed in card radio component for bank account
.radio-bank-icon {
	display: inline-block;
	font-size: rem(32px);
	width: rem(32px);
	margin-right: $spacer-xsmall;
	margin-top: rem(-4px);
}


.img-hover {
	display: block;
	position: relative;
}

.no-touchevents .img-hover {
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-color: $black;
		opacity: 0;
		@include transition(opacity 0.3s $ease);
	}

	&:hover:before {
		opacity: 0.2;
	}
}

.popup-img {
	width: 100%;
	border: rem(1px) solid $medium-line-color;
	position: relative;
	display: inline-block;

	img {
		width: 100%;
	}

	figcaption {
		padding: $spacer-small;
		background-color: $light-line-color;
	}
}
