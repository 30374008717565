$animation-speed: 0.2s;

.animate--in-transition {
	// There is an issue with the css-animation-event
	// package and uglify version 5.2.2.
	// This class never gets removed upon completion
	// of the animation. When the issue is resolved,
	// uncomment the following line:
	// pointer-events: none; // prevents unwanted click activity during transition
}

.animate,
.animate-all {
	@include transition(all $animation-speed $ease);
}

.animate--out {
	@include transform(scale(0.9, 0.9));
	opacity: 0;
}
.animate--in {
	@include transform(scale(1, 1));
	opacity: 1;
}

.animate-fade {
	will-change: opacity;
	@include transition(opacity $animation-speed $ease);
}
.animate-fade--out {
	opacity: 0;
}
.animate-fade--in {
	opacity: 1;
}

.animate-zoom {
	will-change: transform;
	@include transition(transform $animation-speed $ease);
}
.zoom--in {
	@include transform(scale(1, 1));
}
.zoom--out {
	@include transform(scale(0, 0));
}

