/***************************************************
	BOXES
***************************************************/


/** BACKGROUND COLOR HELPERS
---------------------------------------- **/

.bg-highlight {
	background-color: $box-highlight-color;
}

.bg-alternate {
	background-color: $box-alternate-color;
}

.bg-alternate-light {
	background-color: $card-header-background-color !important;
}

.bg-contrast {
	background-color: $box-contrast-color;
}

.bg-feature {
	background-color: $box-feature-color;
	&, a {
		color: $link-color;
	}

	.no-touchevents & a:hover {
		color: $link-hover-color;
	}
}

.bg-white {
	background-color: $white
}

/** GUTTERS AND BUFFERS
---------------------------------------- **/

/**
  * @name Text-Layer
  * @description Center aligned text layers.
  */
.text-layer {
	padding: 0 1em;
	margin: 0 auto;
	float: none;
	clear: both;
}

.text-layer-highlight {
	@extend .text-layer;
	margin: 0 auto 0 auto;
	padding: rem(20.5px) rem(15px) rem(28px);
	background-color: $box-important-color;
}


/** VERTICAL ALIGNMENT
---------------------------------------- **/
%valign {
	@include display(flex);
	@include flex-wrap(nowrap);
	@include align-items(center);
}

.valign-middle {
	@extend %valign;
}

.valign-middle-med-up {
	@media only screen and (min-width: $breakpoint-small) {
		@include display(flex);
		@include flex-wrap(nowrap);
		@include align-items(center);
	}
}

.valign-middle-med-up--justify {
	@extend .valign-middle-med-up;
	@media only screen and (min-width: $breakpoint-small) {
		@include justify-content(space-between);
	}
}

.valign-middle--align-right {
	@extend %valign;
	@include justify-content(flex-end);
}

.valign-middle-small-up--align-right {
	@extend %valign;
	@include justify-content(space-between);
	@media only screen and (min-width: $breakpoint-small) {
		@include justify-content(flex-end);
	}
}

.valign-middle-med-up--align-right {
	@extend %valign;
	@include justify-content(space-between);
	@media only screen and (min-width: $breakpoint-medium) {
		@include justify-content(flex-end);
	}
}

.valign-middle--align-center {
	@extend %valign;
	@include justify-content(center);
}

.valign-justify {
	@include display(flex);
	@include flex-wrap(nowrap);
	@include flex-direction(column);
	@include justify-content(space-between);
	@include align-items(stretch);
}

.reverse-direction {
	flex-direction: row-reverse;
}
.reverse-direction-med-up {
	@media only screen and (min-width: $breakpoint-small) {
		flex-direction: row-reverse;
	}
}

// Helper classes for absolutely positioning an element
$anchor-spacing: 0.5em;
.anchor-parent {
	position: relative;
}

.anchor-top-right {
	position: absolute;
	top: $anchor-spacing;
	right: $anchor-spacing;
	z-index: 1;
}

.anchor-top-left {
	position: absolute;
	top: $anchor-spacing;
	left: $anchor-spacing;
	z-index: 1;
}

.anchor-bottom-right {
	position: absolute;
	bottom: $anchor-spacing;
	right: $anchor-spacing;
	z-index: 1;
}

.anchor-bottom-left {
	position: absolute;
	bottom: $anchor-spacing;
	left: $anchor-spacing;
	z-index: 1;
}

// An unclickable element placed over other content, use in conjunction with anchor positioning classes above
.anchored-badge {
	background-color: rgba($black, 0.9);
	border-radius: rem(3px);
	color: $white;
	padding: 0.2em;
	pointer-events: none;
}

/**
  * @name Alert Image
  * @description Style for alert page icon. Use either svg image or icon class.
  * @markup
  *   <i class="triangleMortarIcon alert-img" aria-hidden="true"></i>
  *   <img class="alert-img" src="something.svg">
  */
.alert-img {
	display: block;
	margin: rem(30px) auto rem(20px);
	font-size: rem(100px);
	color: $base-text-color;
}

// If the element is an image, match the size
img.alert-img {
	width: rem(100px);
}

.alert-img-success {
	@extend .alert-img;
	color: $success-color;
}

.alert-img-warn {
	@extend .alert-img;
	color: $warn-color;
}

.alert-img-alert {
	@extend .alert-img;
	color: $alert-color;
}

.alert-img--small {
	margin: rem(20px) auto;
	font-size: rem(50px);
}

.img.alert-img--small {
	width: rem(50px);
}

@include keyframes(fade-up-in) {
	0% {
		opacity: 0;
		@include transform(translateY(-100px));
	}
	100% {
		opacity: 1;
		@include transform(translateY(0));
	}
}
.alert-img--animated {
	opacity: 0;
	@include transform(translateY(-100px));
	@include animation(fade-up-in 0.5s $ease-in-out-back 1 forwards 0.5s);
}


// Add a stylized left border to an element, to add a stronger color emphasis

.colored-left-border {
	border-left-style: solid;
	border-left-width: rem(4px);
	padding-left: rem(10px);
	border-left-color: $base-text-color;
}

.colored-left-border--success {
	@extend .colored-left-border;
	border-left-color: $ui-primary-color;
}

.colored-left-border--warn {
	@extend .colored-left-border;
	border-left-color: $warn-text-color;
}

.colored-left-border--alert {
	@extend .colored-left-border;
	border-left-color: $alert-text-color;
}


// Adds a colored circle status icon

.status-oval-wrapper {
	display: flex;

	&:last-child {
		flex-grow: 1;
	}
}

.oval {
	height: rem(12px);
	width: rem(12px);
	flex: 0 0 rem(12px);
	border-radius: 50%;
	display: inline-block;
	position: relative;
	top: rem(6px);
	margin: 0 1em 0 0;
}

.node-success {
	border: rem(2px) solid $apts-green;
	background-color: $apts-green;
}

.node-warn {
	border: rem(2px) solid $goldenrod;
	background-color: $goldenrod;
}

.node-default {
	border: rem(2px) solid $medium-gray;
}



/**
 * collapsible triggers and containers
 */
.collapsible-trigger {
	cursor: pointer;
}

.collapsible-trigger-title {
	@include transition(opacity 0.2s $ease);
}

.collapsible-trigger:hover .collapsible-trigger-title {
	opacity: 0.7;
}

.collapsible-trigger-indicator {
	@extend .mortar-icon;
	@extend .down2MortarIcon;
	vertical-align: middle!important;
	color: $charcoal;
	font-size: rem(25px);
	text-align: right;
	padding-right: 0;
	display: inline-block;
	cursor: pointer;
	&:before {
		display: inline-block;
		@include transition(transform 0.2s $ease);
		@include transform(rotate(0deg));
	}
	@media only screen and (min-width: $breakpoint-small) {
		padding-right: .6em;
	}
}

.collapsible-trigger-active {
	.collapsible-trigger-indicator:before {
		@include transform(rotate(180deg));
	}
}

.collapsible-toggler {
	font-size: 1em;
	color: $base-text-color;
	display: inline-block;
	@include transition(transform .2s $ease);
}

.collapsible-toggler-up i:before {
	@extend .collapsible-toggler;
	@include transform(rotate(0deg));
}

.collapsible-toggler-right i:before {
	@extend .collapsible-toggler;
	@include transform(rotate(90deg));
}

.collapsible-toggler-left i:before {
	@extend .collapsible-toggler;
	@include transform(rotate(270deg));
}

.collapsible-toggler-down i:before {
	@extend .collapsible-toggler;
	@include transform(rotate(180deg));
}

.collapsible {
	overflow: hidden;
	@include transform (translate3d(0,0,0));
	@include transition (all 0.3s $ease);
}

@media print {
	.collapsible-trigger-indicator {
		display: none;
	}
}

.collapsible:not(.collapsible-open) {
	// set this so that focusable elements (button, link, etc) within the collapsed panel
	// are not focusable while closed. using visiblity because display does not
	// honor transition timing.
	visibility: hidden;

	// I have no idea why but setting .collapsible max-height 100px for print does not
	// work. But setting it to max-height 0 for screen only does work.
	@media screen {
		max-height: 0;
	}
	@media print {
		visibility: visible;
	}
}

.collapsible.collapsible-open {
	visibility: visible;
	max-height: rem(1500px);
}

.hoverable:hover {
	cursor: pointer;
}

.no-touchevents .hoverable {
	&:hover .hoverable-text {
		color: $link-color !important;
		@include transition(color 0.2s $ease);
	}
}

.no-touchevents .hoverable-text {
	color: $base-text-color !important;
}

/**
 * Cards
 */
.card {
	display: block;
	position: relative;
	@extend .clearfix;
	padding: 0;
	border: rem(1px) solid $box-border-color;
	background-color: $white;
	margin-bottom: $spacer-medium;

	// Card overflow should be visible so absolute positioned components
	// such as date-picker render correctly.
	overflow: visible;

	border-radius: rem(4px);
	$card-inner-border-radius: rem(3px);

	> *:first-child {
		border-radius: $card-inner-border-radius $card-inner-border-radius 0 0;
	}
	> *:last-child {
		border-radius: 0 0 $card-inner-border-radius $card-inner-border-radius;
	}
	> *:only-child {
		border-radius: $card-inner-border-radius;
	}

	&:last-child {
		margin-bottom: 0;
	}

	// Cards inside cards get their own treatment
	.card {
		border-radius: rem(2px);
		box-shadow: none;
		margin-bottom: em(16px, $em-base);
	}

	hr:before {
		background-color: $box-background-color;
	}

	// match .card-body
	hr.to-edge {
		margin-left: -$spacer-small;
		margin-right: -$spacer-small;
		@media only screen and (min-width: $breakpoint-small) {
			margin-left: -$spacer-medium;
		margin-right: -$spacer-medium;
		}
	}

	// standard card children
	.card-body, .card-footer {
		padding: $spacer-small;
		@media only screen and (min-width: $breakpoint-small) {
			padding: $spacer-medium;
		}
	}

	&__title {
		font-size: rem(16px);
		font-weight: $regular;
		// TODO: Remove when base typography is fixed
		padding: 0 !important;
		margin-bottom: 0;
	}

	&--rounded,
	& .card--rounded {
		border-radius: rem(5px);
	}

	&--link {
		@extend .link-unstyled;
		&:focus {
			outline: none;
			border-color: $apts-green;
			@include focus-box-shadow($apts-green);
		}
	}

	&--stretch-to-parent-height {
		height: 100%;
	}
}

.card-header {
  position: relative;
	background-color: $card-header-background-color;
	font-weight: $regular;
	@extend .separator-bottom;
  padding: $spacer-small $spacer-medium;
}

.card-body.muted,
.card-footer {
	background-color: $white;

	hr:before {
		background-color: $light-line-color;
	}
}


.card-close {
	@extend .mortar-icon;
	@extend .closeMortarIcon;
	text-decoration: none!important;
	color: $base-text-color;
	font-size: rem(24px);
	margin-right: -.8em;
	padding: .5em;
}

.btn.card-header-action {
	@extend .btn;
}

.btn-alt.card-header-action {
	@extend .btn-alt;
}

.btn-alt.card-header-action,
.btn.card-header-action {
	margin: 0 !important;
	@extend .btn-small;
}

.card-flow {
	// cards inside flows, or less information dense
	@media only screen and (min-width: $breakpoint-small) {
		margin-top: $spacer-xsmall;
	}
	.card-body, .card-footer {
    padding: $spacer-small;
		@media only screen and (min-width: $breakpoint-small) {
			padding: $spacer-large;
		}
	}
}

.card-header,
.card-body + .card-body,
.card-footer {
	margin-top: rem(-1px); // prevent double border if element above has bottom border
	border-top: rem(1px) solid $box-border-color;
}

.card-body {
	font-size: rem(16px);
}

.card--selected {
	border-color: $dark-line-color;
}

@media only screen and (min-width: $breakpoint-medium) {
	.card-body.scrollable {
		overflow-y: scroll;
		height: rem(500px);
	}
}

.separator-right {
	border-right: rem(1px) solid $box-border-color;
}

.separator-left {
	border-left: rem(1px) solid $box-border-color;
}

.separator-top {
	border-top: rem(1px) solid $box-border-color;
}

.separator-bottom {
	border-bottom: rem(1px) solid $box-border-color;
}

@media only screen and (min-width: $breakpoint-small) {
	.separator-right-for-medium-up {
		border-right: rem(1px) solid $box-border-color;
		&--with-padding {
			@extend .separator-right-for-medium-up;
			padding-right: rem(20px);
		}
	}

	.separator-left-for-medium-up {
		border-left: rem(1px) solid $box-border-color;
		&--with-padding {
			@extend .separator-left-for-medium-up;
			padding-left: rem(10px);
		}
	}
}

// styling for recent payments list container

.list-container {
	border: rem(1px) solid $box-border-color;
	border-radius: rem(4px);
	li {
		border-bottom: rem(1px) solid $box-border-color;
	}
	li:last-child {
		border-bottom: none;
	}
}

.list-container-padding {
	@media only screen and (max-width: $breakpoint-small) {
		padding: 0 rem(20px);
	}
}

.list-container-padding-left {
	padding-left: rem(20px);
	@media only screen and (max-width: $breakpoint-small) {
		padding-left: 0;
	}

}

.list-container-padding-right {
	padding-right: rem(20px);
	@media only screen and (max-width: $breakpoint-small) {
		padding-right: 0;
	}
}

// ----------------------------------------------
// Layout for Banks & Cards
// ----------------------------------------------

// In laptop/desktop viewport only, cards are 2 per row
@media only screen and (min-width: $breakpoint-small) {
	.banks-cards__overview-row {
		// needs to match grid system
		margin-left: -0.9375em;
		margin-right: -0.9375em;
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
	}

	.banks-cards__card-wrapper {
		flex: 0 0 50%;
		max-width: 50%;
		padding-left: 0.9375em;
		padding-right: 0.9375em;
		.card {
			height: 100%;
		}
	}
}

.banks-cards__card-wrapper {
	p {
		margin-bottom: 0;
	}
	.highlight-red {
		border-color: $crimson;
		&:focus {
			@include focus-box-shadow($crimson);
		}
	}
}

// Absolutely position the card/bank icon
.banks-cards__type-icon-wrapper {
	position: absolute;
	width: $spacer-large * 2;
	left: 0;
	text-align: center;
}

.banks-cards__card-body.card-body {
	padding-left: $spacer-large * 2;
}

// Detail views
.banks-cards-detail__table.card-body {
	padding-top: $spacer-medium / 2;
	padding-bottom: $spacer-medium / 2;
}

.banks-cards-detail__table-row {
	width: 100%;
	padding: ($spacer-medium / 2) 0;
	> p {
		margin: 0;
		&:first-child {
			margin-right: auto;
			padding-right: $spacer-medium;
		}
	}

	.collapsible-trigger-indicator {
		padding-right: 0;
		padding-left: rem(8px);
		color: $charcoal;
	}

	@media only screen and (min-width: $breakpoint-small) {
		display: flex;
		justify-content: space-between;
	}
}

// When the row is a button (like to activate a dropdown)
.banks-cards-detail__interactive-table-row {
	@extend .banks-cards-detail__table-row;
	// resetting default styles
	border-color: transparent;
	border-style: solid;
	border-width: rem(1px);
	background: 0;
	border-radius: rem(4px);

	// making it look okay when focused
	width: calc(100% + #{$spacer-medium});
	padding-left: $spacer-medium / 2;
	padding-right: $spacer-medium / 2;
	margin-left: -($spacer-medium / 2);

	&:focus, &:focus-visible {
		@include focus-box-shadow($apts-green);
		border-color: $avocado;
		outline: none;
	}
}

.banks-cards-detail__table-subrow {
	@extend .banks-cards-detail__table-row;
	position: relative;
	padding-left: rem(36px);
	&:before {
		content: "";
		position: absolute;
		width: rem(20px);
		left: rem(4px);
		top: rem(15px);
		bottom: 50%;
		border-left: rem(1px) solid $medium-gray;
		border-bottom: rem(1px) solid $medium-gray;
	}
}

.banks-cards-detail__table-row-action-btn {
	@media only screen and (max-width: $breakpoint-small) {
		float: right;
	}
}

.card-detail__brand-img {
	height: 1.5em; // match font line height
	margin-left: rem(6px);
}

.negate-card-horizontal-padding {
	margin-left: -$spacer-medium;
	margin-right: -$spacer-medium;
}

.banks-cards-detail__update-te {
	@extend .btn-unstyled;
	@extend .focus-outline;
	font-weight: $regular;
	line-height: rem(24px);
	color: $cerulean-dark;
	&:hover,
	&:focus {
		// in case there's an icon
		text-decoration: none!important; // sorry :<
		span {
			text-decoration: underline;
		}
	}
}

.banks-cards-detail__update-te--alert {
	@extend .banks-cards-detail__update-te;
	@extend .text-alert;
}

// ----------------------------------------------
// Stripe Connections card
// ----------------------------------------------

.connections-wrapper {
	position: relative;
}

.connections-loading-overlay {
	position: absolute;
	top: rem(1px);
	bottom: rem(1px);
	left: rem(1px);
	right: rem(1px);
	background: $white;
	margin: 0!important;
	z-index: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.connections-intro {
	font-weight: $regular;

	@media screen and (min-width: $breakpoint-medium) {
		grid-template-columns: 2fr 1fr;
		p {
			margin-bottom: 0;
		}
	}
}

.add-account-card-header {
	color: $black;
	font-size: rem(22px);
	font-weight: $regular;
}

.connections-intro-header {
	@media screen and (min-width: $breakpoint-medium) {
		padding-bottom: 1em;
	}
}

a.stripe-connections-stripe-logo {
	display: inline-block;
	vertical-align: sub;
	line-height: 0;
	width: rem(55px);
}
