
.error-container {
	margin: auto;
	text-align: center;
	padding: 1em;
	max-width: 35em;
}
.error-card {
	@extend .card;
	padding: 2em;
	margin: 1em 0;
}
.error-img {
	margin: rem(10px) auto;
	font-size: rem(100px);
	color: $base-text-color;
	display: inline-block;
}

@media only screen and (min-width: $breakpoint-medium) {
	.error-container {
		margin-top: 5em;
	}
	.error-card {
		padding: 2em 4em;
	}
}

@include keyframes(teeter) {
	0% {
		@include transform(rotate(0deg));
	}
	25% {
		@include transform(rotate(10deg));
	}
	50% {
		@include transform(rotate(-10deg));
	}
	75% {
		@include transform(rotate(10deg));
	}
	100% {
		@include transform(rotate(-70deg));
	}
}
.error-img {
	@include animation(teeter 1s $ease 1 forwards 0.5s);
}
