/***************************************************
  OTP Form Card
***************************************************/
.otp-card {
	text-align: center;
	padding: $spacer-small $spacer-xsmall;

	@media only screen and (min-width: $breakpoint-small) {
		height: rem(260px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	label {
		color: $avocado;
	}

	.otp-code-form {
		.verification-prefix, input {
			font-family: $mono;
			font-size: 4ch;
			color: $black;
			border: none;
			padding: 0;
			text-align: left;
			height: unset;
			text-transform: uppercase;

			@media only screen and (max-width: $breakpoint-small) {
				font-size: 2.8ch;
			}
		}

		.verification-prefix {
			letter-spacing: 1ch;
			width: 3ch;
		}

		input {
			caret-color: unset;
			margin: 0.5em -2ch 0.5em 0;
			letter-spacing: 1ch;
			width: 9ch;
			background: repeating-linear-gradient(90deg, $medium-gray 0, $medium-gray 1ch, transparent 0, transparent 2ch) 0 100%/7ch 3px no-repeat;
		}

		input:focus {
			outline: none;
		}
	}

  &:focus-within {
    border-color: $npt-focus-border-color;
    @include npt-box-shadow($npt-focus-box-shadow-color);
    background-color: $npt-background-color;
  }
}

.otp-error {
  @include npt-border-color($npt-error-color, true);
  &:focus-within {
    @include npt-box-shadow($npt-error-color);
  }
}
