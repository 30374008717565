/**
 * Timeline component
 */

 .timeline-row {
	display: flex;
	flex-direction: row;
	position: relative;

	&:after {
		content: "";
	  	height: calc(100% - #{rem(23px)});
	  	border-left: $medium-gray rem(2px) solid;
	  	position: absolute;
	  	left: rem(6px);
		right: 0;
		bottom: rem(-2px);
	}

	&:last-child {
		&:after {
			display: none;
	  	}
	}

	.graphic {
		display: flex;
		flex-direction: column;
		align-items: center;

		.node {
			position: relative;
			top: rem(-1px);
			width: 1em;
			height: 1em;
			border-radius: rem(50px);
			margin: .5em 0 .5em 0;

			&.small {
			    width: .75em;
			    height: .75em;
			    left: rem(1px);
			}
		}
	}

	[class^="timeline-label"] {
		font-weight: $regular;
	}
	.timeline-label--default {
		font-size: rem(18px);
	}
	.timeline-label--small {
		font-size: rem(16px);
	}

	.content {
		// mobile-styling
		.title .timeline-label--default,
		.title .timeline-label--small,
		.titleWithEntries .timeline-label--default,
		.titleWithEntries .timeline-label--small {
			margin: 0 1.5em .5em 1em;
		}

		.details {
			ul {
				list-style: none;
			}

			li {
				margin-bottom: 1em;
			}
		}

		// above-mobile styling
		@media screen and (min-width: $breakpoint-small) {
			display: flex;
			flex-wrap: wrap;

			.title .timeline-label--default,
			.title .timeline-label--small,
			%title {
				margin: 0 1.5em 0 1em;
				width: 14em;
			}

			.titleWithEntries .timeline-label--default,
			.titleWithEntries .timeline-label--small {
				@extend %title;
				width: 7em;
			}

			.details {
				padding-bottom: 2em;
			}
		}
	}
}
