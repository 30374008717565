// Padding, left and right
.gutter-left-none, .gutter-none { padding-left: 0 !important; }
.gutter-right-none, .gutter-none { padding-right: 0 !important; }
.gutter-left-xtiny, .gutter-xtiny { padding-left: $spacer-xxsmall !important; }
.gutter-right-xtiny, .gutter-xtiny { padding-right: $spacer-xxsmall !important; }
.gutter-left-tiny, .gutter-tiny { padding-left: $spacer-xsmall !important; }
.gutter-right-tiny, .gutter-tiny { padding-right: $spacer-xsmall !important; }
.gutter-left-small, .gutter-small { padding-left: $spacer-small !important; }
.gutter-right-small, .gutter-small { padding-right: $spacer-small !important; }
.gutter-left-medium, .gutter-medium, .gutter, .gutter-left { padding-left: $spacer-medium !important; }
.gutter-right-medium, .gutter-medium, .gutter, .gutter-right { padding-right: $spacer-medium !important; }
.gutter-left-large, .gutter-large { padding-left: $spacer-large !important; }
.gutter-right-large, .gutter-large { padding-right: $spacer-large !important; }
.gutter-left-xlarge, .gutter-xlarge { padding-left: $spacer-xlarge !important; }
.gutter-right-xlarge, .gutter-xlarge { padding-right: $spacer-xlarge !important; }

.gutter-left-small-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    padding-left: $spacer-small !important;
  }
}

.gutter-left-small-for-large-up {
  @media only screen and (min-width: $breakpoint-medium) {
    padding-left: $spacer-small !important;
  }
}

.gutter-left-tiny-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-left: $spacer-xsmall !important;
  }
}

.gutter-left-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-left: $spacer-medium !important;
  }
}

.gutter-right-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-right: $spacer-small !important;
  }
}

.gutter-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-left: $spacer-medium !important;
    padding-right: $spacer-medium !important;
  }
}

.gutter-large-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-left: $spacer-large !important;
    padding-right: $spacer-large !important;
  }
}

.gutter-xlarge-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-left: $spacer-xlarge !important;
    padding-right: $spacer-xlarge !important;
  }
}

// Padding, top and bottom
.padder-top-none, .padder-none { padding-top: 0 !important; }
.padder-bottom-none, .padder-none { padding-bottom: 0 !important; }
.padder-top-xtiny, .padder-xtiny { padding-top: $spacer-xxsmall !important; }
.padder-bottom-xtiny, .padder-xtiny { padding-bottom: $spacer-xxsmall !important; }
.padder-top-tiny, .padder-tiny { padding-top: $spacer-xsmall !important; }
.padder-bottom-tiny, .padder-tiny { padding-bottom: $spacer-xsmall !important; }
.padder-top-small, .padder-small { padding-top: $spacer-small !important; }
.padder-bottom-small, .padder-small { padding-bottom: $spacer-small !important; }
.padder-top-medium, .padder-medium, .padder, .padder-top { padding-top: $spacer-medium !important; }
.padder-bottom-medium, .padder-medium, .padder, .padder-bottom { padding-bottom: $spacer-medium !important; }
.padder-top-large, .padder-large { padding-top: $spacer-large !important; }
.padder-bottom-large, .padder-large { padding-bottom: $spacer-large !important; }
.padder-top-xlarge, .padder-xlarge { padding-top: $spacer-xlarge !important; }
.padder-bottom-xlarge, .padder-xlarge { padding-bottom: $spacer-xlarge !important; }

.padder-top-small-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    padding-top: $spacer-small !important;
  }
}
.padder-top-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-top: $spacer-medium !important;
  }
}

.padder-large-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-top: $spacer-large !important;
    padding-bottom: $spacer-large !important;
  }
}

.padder-small-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-top: $spacer-small !important;
    padding-bottom: $spacer-small !important;
  }
}

.padder-medium-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    padding-top: $spacer-medium !important;
    padding-bottom: $spacer-medium !important;
  }
}

// Margin, top and bottom
.buffer-top-none, .buffer-none { margin-top: 0 !important; }
.buffer-bottom-none, .buffer-none { margin-bottom: 0 !important; }
.buffer-top-xtiny, .buffer-xtiny { margin-top: $spacer-xxsmall !important; }
.buffer-bottom-xtiny, .buffer-xtiny { margin-bottom: $spacer-xxsmall !important; }
.buffer-top-tiny, .buffer-tiny { margin-top: $spacer-xsmall !important; }
.buffer-bottom-tiny, .buffer-tiny { margin-bottom: $spacer-xsmall !important; }
.buffer-top-small, .buffer-small { margin-top: $spacer-small !important; }
.buffer-bottom-small, .buffer-small { margin-bottom: $spacer-small !important; }
.buffer-top-medium, .buffer-medium, .buffer, .buffer-top { margin-top: $spacer-medium !important; }
.buffer-bottom-medium, .buffer-medium, .buffer, .buffer-bottom { margin-bottom: $spacer-medium !important; }
.buffer-top-large, .buffer-large { margin-top: $spacer-large !important; }
.buffer-bottom-large, .buffer-large { margin-bottom: $spacer-large !important; }
.buffer-top-xlarge, .buffer-xlarge { margin-top: $spacer-xlarge !important; }
.buffer-bottom-xlarge, .buffer-xlarge { margin-bottom: $spacer-xlarge !important; }

.buffer-small-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $spacer-small !important;
    margin-top: $spacer-small !important;
  }
}

.buffer-bottom-large-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $spacer-large !important;
  }
}

.buffer-bottom-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    margin-bottom: $spacer-medium !important;
  }
}

.buffer-bottom-small-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $spacer-small !important;
  }
}

.buffer-bottom-small-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    margin-bottom: $spacer-small !important;
  }
}

.buffer-bottom-tiny-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $spacer-xsmall !important;
  }
}

.buffer-bottom-xtiny-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $spacer-xxsmall !important;
  }
}

.buffer-top-xtiny-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $spacer-xxsmall !important;
  }
}

.buffer-top-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $spacer-medium !important;
  }
}

.buffer-top-for-large-up {
  @media only screen and (min-width: $breakpoint-medium) {
    margin-top: $spacer-medium !important;
  }
}

.buffer-bottom-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-bottom: $spacer-medium !important;
  }
}

.buffer-top-tiny-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $spacer-xsmall !important;
  }
}

.buffer-top-tiny-for-medium-up {
  @media only screen and (min-width: $breakpoint-small) {
    margin-top: $spacer-xsmall !important;
  }
}

.buffer-top-small-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $spacer-small !important;
  }
}

.buffer-top-medium-for-small-only {
  @media only screen and (max-width: $breakpoint-small) {
    margin-top: $spacer-medium !important;
  }
}

.buffer-top-xlarge-for-large-up {
  @media only screen and (min-width: $breakpoint-medium) {
    margin-top: $spacer-xlarge !important;
  }
}

.buffer-top-none-for-large-up {
  @media only screen and (min-width: $breakpoint-medium) {
    margin-top: 0 !important;
  }
}

// Margin, left and right - add classes as needed
.buffer-right-tiny {
  margin-right: $spacer-xsmall !important;
}

.buffer-right-small {
  margin-right: $spacer-small !important;
}

.buffer-left-none {
  margin-left: 0 !important;
}
