.npt-fieldset--candybar {
  @extend .npt-fieldset;
}

// label and helper text
.npt-label,
.npt-info {
  @extend %npt-outer-text;
}
.npt-label {
  font-size: $npt-font-size;
}
.npt-label-contents {
  display: inline-block;
  position: relative;
}

// waiting (loading) state
.npt-loader {
  display: none;
  @extend .spinner-soft;
  @extend .spinner-soft--small;
  position: absolute;
  right: rem(-24px);
  top: rem(4px);
  .npt-waiting & {
    display: inline-block;
  }
}

.npt-info {
  margin-top: $npt-helper-text-spacer;
  font-size: $npt-font-size-secondary;
  margin-bottom: 0;
}

// wraps .npt-body and .npt-inline-button
.npt-wrapper {
  position: relative;
  width: 100%;
  @include display(flex);
}
// inputs + addons
.npt-body {
  @include flex(1);
  @include display(flex);
  @include npt-transition(all);

  position: relative;
  width: 100%;
  border-radius: $npt-border-radius;
  border: $npt-border-width solid $npt-border-color;
  font-size: $npt-font-size;
  background-color: $npt-background-color;

  // success icon
  &:after {
    @include npt-transition(color);
    font-size: $npt-icon-size;
    position: absolute;
    right: 0;
    padding-right: $npt-padding;
    line-height: $npt-height;
    content: '';
    color: transparent;
  }

  &:not(.npt-body--stripe):focus-within {
    border-color: $npt-focus-border-color;
    @include npt-box-shadow($npt-focus-box-shadow-color);
    background-color: $npt-background-color;
  }

  .has-inline-button &:not(.inline-button-is-hidden) {
    &, .npt-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }
  }
}

.npt-inline-button {
  // trying to keep generic - add more classes if needed to inlineBlock.css
  background: 0;
  border: $npt-border-width solid $npt-success-color;
  border-radius: 0 $npt-border-radius $npt-border-radius 0;
  height: $npt-height + rem(2px);
  padding-left: $npt-padding;
  padding-right: $npt-padding;
  z-index: 1;
  text-align: center;
  cursor: pointer;
  color: $npt-icon-color;
  @include transition(all 0.2s $ease);
  i {
    font-size: $npt-icon-size;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: $npt-font-size;
    font-weight: $regular;
  }
  &:hover {
    background: $button-secondary-hover-color;
  }
  &:focus {
    @include npt-box-shadow($npt-focus-box-shadow-color);
    border-color: $npt-focus-border-color;
    border-left-width: $npt-border-width;
    outline: 0;
  }
}

// input class
.npt-field {
  @include appearance(none);
  @include order(2);
  @include flex(0 1 auto);
  @include npt-transition;
  font-size: $npt-font-size;
  font-weight: $light;
  color: $npt-text-color;
  height: $npt-height;
  min-height: $npt-height;
  line-height: $npt-line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0;
  background: 0;
  border-radius: $npt-border-radius;
  box-shadow: none;
  width: 100%;
  padding-left: $npt-padding;
  padding-right: $npt-padding;
  padding-top: 0;
  padding-bottom: 0;
  &:focus {
    outline: 0;
  }

  @include placeholder {
    color: $npt-placeholder-text-color;
  }
}

/***************************************************
ADD-ONs
***************************************************/

.npt-addon {
  white-space: nowrap;
  @include flex(none);
  @include npt-transition;

  position: relative;
  line-height: $npt-height;
  color: $npt-text-color;

  &:empty {
    padding: 0;
    background-color: $white;
  }
}

a.npt-addon {
  color: $link-color;
}

.npt-addon-before {
  @include order(1);
  @extend .npt-addon;
  padding-left: $npt-padding;
  border-radius: $npt-border-radius 0 0 $npt-border-radius;
  border-right: 0;
  font-size: $npt-font-size;
}
.npt-addon-after {
  @include order(3);
  @extend .npt-addon;
  padding-right: $npt-padding;
  border-radius: 0 $npt-border-radius $npt-border-radius 0;
  border-left-width: 0;
  font-size: $npt-font-size;
}

// if you have addonAfter as well as the checkmark,
// give them some breathing room
.npt-show-valid-state .npt-valid .npt-addon-after {
  padding-right: $npt-icon-size-small + ($npt-padding * 2);
}

// images inside of inputs, like custom credit card icons
.input-icon {
  &-card-back input {
    @include background-image-base;
    background-image: url('/img/card-icons/cvv-back.svg');
  }

  &-card-front input {
    @include background-image-base;
    background-image: url('/img/card-icons/cvv-front.svg');
  }
}

// we need our inputs to have border radius usually,
// but remove it if they have addons to the left or right
.npt-with-addon-before {
  .npt-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.npt-with-addon-after {
  .npt-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

/***************************************************
  INFO MESSAGES
***************************************************/

.npt-msg {
  @include npt-transition;
  overflow: hidden;
  max-height: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;

  a, a:visited, a:hover, a:focus {
    color: inherit;
    text-decoration: underline;
  }
}
.npt-msg {
  font-size: $npt-font-size-secondary;
  color: $npt-text-color;
}

/***************************************************
  STATES
***************************************************/

// invalid - error :(
.npt-invalid {
  .npt-body,
  .npt-inline-button {
    @include npt-border-color($npt-error-color, true);
    &:focus-within {
      @include npt-box-shadow($npt-error-color);
    }
  }
  .npt-inline-button {
    color: $npt-error-color;
    &:hover {
      background: $rose;
    }
    &:focus, &:focus-visible {
      @include npt-box-shadow($npt-error-color);
    }
  }
}

.npt-invalid ~ .npt-msg,
.npt-msg.show-stripe-error-reason {
  color: $npt-error-color;
  &:not(:empty) {
    margin: $npt-helper-text-spacer 0;
    max-height: none;
  }
}

// valid - success!
.npt-valid {
  & ~ .npt-msg {
    color: $npt-success-color;
    &:not(:empty) {
      margin: $npt-helper-text-spacer 0;
      max-height: none;
    }
  }
}

// when we want to show a checkmark
.npt-show-valid-state .npt-valid {
  // if there's not addonAfter text, make room for the checkmark
  &:not(.npt-with-addon-after) {
    .npt-field {
      padding-right: $npt-icon-size-small + $npt-padding;
    }
  }
  .npt-body {
    // checkmark
    &:after {
      font-family: 'MortarIcons';
      content: "\e913";
      font-size: $npt-icon-size-small;
      color: $npt-icon-color;
    }
  }
}
// custom styles for character limit
// count on textareas
.npt-textarea-wrapper .npt-valid ~ .npt-msg {
  font-weight: $light;
  color: $npt-text-color;
}

// disabled state
.npt-disabled,
fieldset[disabled] {
  .npt-body {
    background-color: $npt-disabled-background-color !important;
  }
  .npt-inline-button, .npt-inline-button:hover, .npt-inline-button:active {
    cursor: not-allowed;
    border-color: $button-disabled-color;
    background-color: $button-disabled-color;
    color: $button-disabled-text-color;
  }
  .npt-field,
  .npt-addon-before,
  .npt-addon-after {
    color: $npt-disabled-color !important;
    cursor: not-allowed;

    // safari fixes
    -webkit-text-fill-color: $npt-disabled-color;
    opacity: 1;
  }
  .npt-body:hover {
    .npt-field,
    .npt-addon-before,
    .npt-addon-after {
      border-color: $npt-border-color !important;
    }
  }
  // any icons
  .npt-body:after {
    color: $npt-disabled-color !important;
  }
}

/***************************************************
  CANDY BAR INPUTS (input, select, textarea)
***************************************************/
.npt-input,
.npt-dropdown,
.npt-textarea {
  @extend .npt-field;
}

.npt-select {
  select {
    padding-right: $npt-padding * 2 + $npt-icon-size; // giving room to arrow
    text-overflow: ellipsis;
    @include appearance(none);
    cursor: pointer;
  }

  // dropdown arrow
  .npt-body {
    &:after {
      @extend .mortar-icon;
      content: "\e9a9";
      position: absolute;
      right: 0;
      pointer-events: none;
      font-size: $npt-icon-size-small;
      color: $npt-icon-color;
      line-height: $npt-height;
    }
  }
}

.npt-textarea {
  overflow: auto;
  padding: $npt-padding;
  line-height: $npt-line-height;
  resize: vertical;
  min-height: $npt-textarea-min-height;
}

/***************************************************
  OTHER INPUTS
***************************************************/

.npt-with-extra {
  position: relative;
}

// sits outside of input, top right
// only seem to be using icons for now.
.npt-extra {
  position: absolute;
  right: 0;
  top: $npt-spacer;
}

.npt-search-icon {
  color: $npt-icon-color;
  i {
    font-size: $npt-icon-size;
  }
}

// We inject stripe elements to capture credit card info,
// and they need a little styling love
.npt-body--stripe {
  border: 0;
  .npt-stripe {
    padding: rem(10px);

    // add 2px since we are zeroing out the border on the parent
    height: $npt-height + rem(2px);
    min-height: $npt-height + rem(2px);

    // false border so doesnt jump when focused
    border: $npt-border-width solid $npt-border-color;

    // fix bug 1887042 - Android zooming way in when focusing on CVC field
    // experimental - may cause other issues. revisit if needed.
    touch-action: none;

    &.StripeElement--focus {
      border: $npt-border-width solid $npt-focus-border-color;
      @include npt-box-shadow($npt-focus-box-shadow-color);
    }
    &.StripeElement--invalid,
    &.stripe-element-has-error {
      border: $npt-border-width solid $npt-error-color;
      &.StripeElement--focus {
        @include npt-box-shadow($npt-error-color);
      }
    }
  }
}
