#meow-container {
	position: fixed;
	top: $spacer-small;
	right: $spacer-small;
	z-index: $humane-z;
	width: rem(300px);
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
}

.humane {
	@include transition(transform .8s $ease, opacity .4s $ease);
	font-size: rem(16px);
	opacity: 0;
	width: 100%;
	color: $base-text-color;
	padding: 1em 3em;
	border-radius: rem(3px);
	box-shadow: 0 rem(2px) rem(8px) 0 rgba(0, 0, 0, .1);
	@include transform(translateY(-40px));
	-webkit-backface-visibility: hidden;
	margin-bottom: 1em;

	&:hover {
		cursor: pointer;
	}

	&:before {
		@extend .mortar-icon;
		font-size: 1.5em;
		position: absolute;
		top: 1.1em;
		left: .5em;
		line-height: 0;
	}
	&.info {
		background-color: $info-light-color;
		border: rem(1px) solid $banner-info-color;
		padding-left: 1em;
	}
	&.success {
		background-color: $banner-success-light-color;
		border: rem(1px) solid $banner-success-color;
		&:before {
			@extend .checkMortarIcon;
			color: $banner-success-color;
		}
	}
	&.alert {
		background-color: $banner-alert-light-color;
		border: rem(1px) solid $banner-alert-color;
		&:before {
			@extend .alertMortarIcon;
			color: $alert-color;
		}
	}
	&.warn {
		background-color: $banner-warn-light-color;
		border: rem(1px) solid $banner-warn-color;
		&:before {
			@extend .triangleMortarIcon;
			color: $warn-color;
		}
	}

	@media only screen and (min-width: $breakpoint-medium) {
		top: ($header-height-medium + rem(15px));
	}
}
.humane p,
.humane ul {
	margin: 0;
	padding: 0;
}
.humane ul {
	list-style: none;
}
.humane.humane-animate {
	opacity: 1;
	@include transform(translateY(0));
}

.humane-dismiss {
	@extend .btn-unstyled;
	@extend .banner-close;
}

