@mixin btn-box-shadow($color) {
  box-shadow: 0 0 rem(4px) $color;
}

.dropdown-wrapper {
  text-align: right;
  position: relative;
  width: 100%;
  display: block;
  margin: 0;
}
.dropdown-anchor {
  position: relative;
}
.dropdown {
  position: absolute;
  z-index: $float-z;
  background-color: $white;
  box-shadow: 0 0 rem(10px) rgba(0,0,0,0.3);
  margin-bottom: 1em;
  border: rem(1px) solid $dark-line-color;
  border-radius: rem(4px);
  right: 0;
  min-width: 16em;
}

.dropdown-body {
  font-size: rem(16px);
  margin-top: rem(-1px); // prevent double border if element above has bottom border
  border-top: rem(1px) solid $box-border-color;
}
.dropdown-link {
  @extend .link-unstyled;
  @extend .link-nounderline;
  color: $base-text-color;
  display: block;
  padding-left: $spacer-small;
  padding-right: $spacer-small;
  line-height: 3;
  font-weight: $regular;
  .no-touchevents &:hover,
  .no-touchevents &:active {
    background-color: $button-secondary-hover-color;
  }
  .no-touchevents &:focus {
    border-color: $button-secondary-hover-color;
    @include btn-box-shadow($button-primary-color);
  }
}