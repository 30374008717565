// Fix iOS safari issue where text is center aligned
.npt-datepicker input::-webkit-date-and-time-value {
  text-align: left;
}

.datepicker-anchor {
	position: relative;
}
.datepicker {
	position: absolute;
	z-index: $float-z;
	background-color: $white;
	box-shadow: 0 0 rem(10px) rgba(0,0,0,0.3);
	margin-bottom: 1em;
	// to render datepicker above input rather than below
	&.datepicker-top-render {
		top: rem(-510px);
	}
}
@media screen and (min-width: $breakpoint-small) {
	.datepicker {
		right: 0;
		min-width: 21em;

	}
}
.datepicker, .dp-header {
	border-radius: rem(4px);
}
.dp-header {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	padding: 1em 0.5em;
	background-color: $avocado;
	@include display(flex);
	@include flex-wrap(nowrap);
	@include align-items(center);
	> * {
		padding: 0 0.5em;
	}
}
.dp-select {
	.npt-select {
		margin: 0;
	}
	.npt-input {
		border-color: $white;
		background-color: $white;
		line-height: 1;
	}
}
.dp-select--month {
	@include flex-grow(2);
}
.dp-select--year {
	@include flex-grow(1.5);
}
.dp-header-link {
	text-align: center;
	color: $white!important;
	padding: 0 0.2em;
	i {
		font-size: $npt-icon-size;
	}
	.no-touchevents &:hover {
		color: $white;
	}
	&:focus {
		outline: rem(1px) solid $medium-line-color!important;
	}
}

.dp-body {}

.dp-traverser {
	padding: 1em;
	text-align: center;
	@include display(flex);
	@include flex-wrap(nowrap);
	@include align-items(center);
}
.dp-traverser-back,
.dp-traverser-next {
	font-size: rem(20px);
	color: $avocado;
	padding: 0 1em;
}
.dp-traverser-period {
	font-size: rem(20px);
	font-weight: $regular;
	@include flex-grow(3);
}

.dp-cal {
	table-layout: fixed;
	width: 100%;
	text-align: center;
	border-collapse: collapse;
	border-spacing: 0;
	td, th {
		padding: 0;
	}
}
.dp-cal-header {
	th {
		font-size: 0.8em;
		color: $base-text-color;
		line-height: 3;
		font-weight: $regular;
	}
}
.dp-cal-body {}

.dp-day {
	color: $base-text-color;
	display: block;
	line-height: 3;
	font-weight: $regular;
	border: 1px solid transparent;
	&:focus { outline: none; }
}

.no-touchevents .dp-day:hover,
.dp-day--focus {
	border-color: $link-color;
	color: $link-color;
}
.no-touchevents .dp-day:hover {
	color: $link-hover-color;
}

.dp-day--inactive,
.no-touchevents .dp-day--inactive:hover {
	border-color: $light-line-color;
	background-color: $light-line-color;
	color: $base-text-color;
	cursor: not-allowed;
}
.no-touchevents .dp-day--selected:hover,
.dp-day--selected {
	border-color: $link-color;
	background-color: $link-color;
	color: $white;
}

.dp-day--selected.dp-day--inactive,
.no-touchevents .dp-day--selected.dp-day--inactive:hover {
	background-color: fade_out($alert-color, 0.7);
	border-color: transparent;
	color: $alert-color;
}

.no-touchevents .dp-day--selected:hover {
	border-color: $link-hover-color;
	background-color: $link-hover-color;
}
.dp-day--outside {
	font-weight: $light;
}

.dp-day--today,
.dp-day--today.dp-day--inactive,
.dp-day--today.dp-day--selected {
	border-color: $box-border-color;
}
