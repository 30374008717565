// Radio bar component (slide between options)
.npt-radiobar {
  .npt-radiobar-option {
    width: 50%;
    margin-right: 0 !important;
    padding: 0;
    border-radius: $npt-border-radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: rem(1px) solid $radio-filter-background-color;
    background: $radio-filter-background-color;

    &:last-of-type {
      border-radius: $npt-border-radius;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:active, &:focus, &:focus-within {
      box-shadow: 0 0 rem(2px) rem(1px) $radio-filter-focus-color;
      outline: 0;
      z-index: 3;
     }

    label {
      display: block;
      text-align: center;
      text-transform: none;
      margin: 0;
      padding: 0;
      line-height: rem(38px);
      color: $radio-filter-background-color;
      font-weight: $regular !important;
    }
  }

  .npt-radiobar-options {
    padding: 0;

    &--right-active {
      .npt-radiobar-option {
        &:first-of-type {
          background: $white;
          &:hover {
            background: $radio-filter-hover-color;
          }
        }

        &:last-of-type {
          background: $radio-filter-background-color;
          border-left: none;
          &:hover {
            background: $radio-filter-background-color;
          }
        }
      }
    }

    &--left-active {
      .npt-radiobar-option {
        &:first-of-type {
          background: $radio-filter-background-color;
          border-right: none;
          &:hover {
            background: $radio-filter-background-color;
          }
        }

        &:last-of-type {
          background: $white;
          &:hover {
            background: $radio-filter-hover-color;
          }
        }
      }
    }
  }
}

// Radio bar
$npt-radiobar-padding: rem(8px);

.npt-radiobar-options {
  // TODO: Until we change the root font size for the entire app,
  // keep this font-size declaration here.
  font-size: rem(16px);
  border-radius: rem(5px);
  background-color: $white;
  position: relative;
  @include display(flex);
  @include align-items(center);
}

.npt-radiobar-option {
  position: relative;
  @include npt-transition(background-color);
  z-index: 2;
  width: 50%;
  display: block;
  overflow: auto;
  padding: $npt-radiobar-padding;
}
.npt-radiobar-option input {
  @extend %npt-hidden;
}
.npt-radiobar-option label {
  background-color: transparent;
  text-align: center;
  display: block;
  cursor: pointer;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  @include npt-transition(color);
}

.npt-radiobar-option input:checked + label {
  color: $white;
}

.npt-radiobar--no-slider {
  margin: $npt-spacer 0;

  .npt-radiobar-options {
    background-color: transparent;
    border-radius: 0;
    border: none;
    padding: 0;
  }

  .npt-radiobar-option label {
    color: $base-text-color;
    padding: 0.7em 0;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    line-height: 1.4em;
  }
  .npt-radiobar-option input:checked + label {
    color: $npt-focus-border-color;
  }

  @media only screen and (min-width: $breakpoint-small) {
    .npt-radiobar-option {
      width: auto;

      &:first-child {
        margin-right: 2.5em;
      }
    }

    .npt-radiobar-option label {
      text-align: left;
    }
  }
}

.npt-radiobar-sub-label {
  padding-left: em(32, $em-base-modified);
}

.npt-radiobar-semi-bold {
   &.npt-radio label {
    font-weight: $regular;
  }
}

// TODO: make this an optional param instead of
// having to pass it in as a css class
.npt-radiobar--natural-width {
  .npt-radiobar-option {
    width: auto;
    padding-left: rem(22px);
    padding-right: rem(22px);
  }
}