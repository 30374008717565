// TODO use this in the forms instead of scoped npt- mixin
@mixin focus-box-shadow($color) {
  box-shadow: 0 0 rem(4px) $color;
}

/***************************************************
	FLEXBOX MIXINS
***************************************************/

// flex webkit extensions
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}
