/**
*
* MENU PANEL
* This is only used to mock the Apartments sidebar, and in the Styleguide.
* It is not currently being used anywhere public-facing
*
**/

/***************************************************
	MENUPANEL CONTAINER
***************************************************/

$menu-width: rem(250px);

.site-wrapper {
	z-index: 300;
	@include transition(transform $ease 0.2s);
}
.menupanel-open {
	position: fixed;
	height: 100%;
	padding-top: 0;

	.view-container {
		overflow: hidden;
	}

	.site-wrapper {
		@include transform(translate3d(-#{$menu-width}, 0, 0));
		height: 100%;
	}

	// ie11 doesn't apply translate when elements are position: fixed.
	// So we'll set them to position:absolute when the panel is open.
	// https://connect.microsoft.com/IE/feedback/details/806517/ie11-tramsform3d-and-position-fixed-problem
	.notify-panel,
	.header,
	.menupanel {
		position: absolute;
	}
}

// Override menupanel behavior for large screens
@media only screen and (min-width: $breakpoint-medium) {
	.menupanel-open {
		position: static;

		.view-container {
			overflow: scroll;
		}

		.site-wrapper {
			@include transform(none);
		}
	}
}

.menupanel {
	width: $menu-width;
	position: fixed;
	top: 0;
	right: -#{$menu-width};
	// This is set to vh because we no longer have a 100% height on any parent element.
	// Otherwise the iframe height is set to the height .menupanel organically takes up.
	height: 100vh;
	z-index: 0;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	background: #f4f4f4;
	border-right: rem(1px) solid #ccc;
}

/***************************************************
	MENUPANEL LIST
***************************************************/
.menu-list {
	margin: 0;
	padding: rem(3px) 0 rem(20px) 0;
	list-style-type: none;
}

.menu-list hr {
	margin: rem(10px) rem(12px);
}

.menu-header, .menu-item-base a {
	color: $header-color;
	display: block;
	padding: rem(12px) rem(15px);
	span {
		display: inline-block;
	}
}

.menu-header {
	padding: rem(5px) rem(15px) rem(10px);
}

.menu-item-base {
	.menu-item-active {
		color: $avocado;
		outline: none;
	}
}

.menu-item {
	font-weight: $regular;
	line-height: 1;
	@extend .menu-item-base;
	a:hover {
		color: $avocado;
	}
}

.menu-item-gutter {
	padding-left: 1.9em;
}

.menu-dividing-buffer {
	margin-top: rem(20px);
}


@media only screen and (min-width: $breakpoint-medium) {
	.menu-list {
		padding-top: rem(20px);
	}
}
