
.loader-overlay {
	display: none;
	position: fixed;
	width:100%;
	height:100%;
	z-index: $processing-z;
}

.loader-processing, .loader-loading {
	@include display(flex);
	@include align-items(center);
}

.loader-processing {
	background: rgba(0,0,0,0.2);
	@include transition(background-color 0.3s $ease);
}

.loader-loading {
	z-index: $loader-z;
	background-color: $base-background-color;
}

.loader-box {
	max-width: 30em;
	border-radius: rem(8px);
	padding: 2em;
	display: inline-block;
	font-size: rem(10px);
	background-color: $white;
	box-shadow: 0px 0px rem(4px) rgba(0, 0, 0, 0.2);
	@include box-sizing(content-box);
	margin: ($header-height-medium + rem(50px)) auto;
	text-align: center;
	vertical-align: middle;
	position: fixed;
	right: 0;
	left: 0;
	// TODO: calculate top inline with iframe helpers
	// keep this as static pixel value to center costar spinner
	top: 200px;
}
// use processing spinner as default
.loader-box .apartments-spinner {
	display: none;
}

.loader-loading {
	.loader-box {
		background-color: transparent;
		box-shadow: none;
	}
	.spinner-soft { display: none; }
	.apartments-spinner { display: inline-block; }
}

.loader-box--no-border {
	box-shadow: none;
	background-color: transparent;
	padding: 0;
}

.loader-message {
	margin-top: 1em;
	font-size: rem($base-font-size);
	text-align: center;
}

.loader-inline {
	display: block;
	text-align: center;
	margin: rem(30px) auto;
	.loader-box {
		position: relative;
		margin: 0 auto;
	}
}

.loader-inline--overlay {
	background-color: $white;
	z-index: 1;
	position: absolute;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: rem(30px) 0;
	top: 0;
	left: 0;
}

.apartments-spinner {
	width: rem(80px);
	height: rem(80px);
}

@include keyframes(rotate) {
	0% {
		@include transform(rotate(0deg));
	}
	100% {
		@include transform(rotate(360deg));
	}
}

.spinner-soft {
	@include animation-name(rotate);
	@include animation-duration(1s);
	@include animation-iteration-count(infinite);
	@include animation-timing-function(linear);
}

.spinner-soft--small {
	width: rem(18px);
}
