table.table-buffer td {
  padding-bottom: 0.5em;
}

.table-card {
	@extend .card;
	padding: 0;
	table {
		td {
			padding-left: rem(20px);
			padding-right: rem(20px);
		}
		tr:first-child {
			td { padding-top: rem(20px); }
		}
		tr:last-child {
			td { padding-bottom: rem(20px); }
		}

		tfoot {
			background-color: $light-line-color;
		}
	}
}

.tr--bordered {
	border-bottom: rem(1px) solid $box-border-color;
}
