
/***************************************************
	BASE STYLES
***************************************************/

$btn-border-radius: rem(3px);

@mixin btn-box-shadow($color) {
  box-shadow: 0 0 rem(4px) $color;
}

%btn-base {
	// Main styles
	background-color: transparent;
	// difference in vertical padding to account for
	// whitespace in rentered font
	padding: .55em 1em .65em;
	border-radius: $btn-border-radius;
	font-size: rem(18px);
	cursor:pointer;
	text-align: center;
	text-decoration: none;
	font-weight: $regular;
	border: rem(1px) solid transparent;
	line-height: 1.4;
	@include transition(all 0.2s $ease);

	&:hover,
	&:focus {
		text-decoration: none !important;
	}

	&:focus {
		outline: none;
	}

	.no-touchevents &:disabled,
	.no-touchevents &:disabled:hover,
	.no-touchevents &.btn-disabled:hover {
		cursor: not-allowed;
		border: rem(1px) solid $button-disabled-color;
		background-color: $button-disabled-color;
		color: $button-disabled-text-color;
	}
}

@media print {
	%btn-base {
		display: none !important;
	}
}

.btn {
	@extend %btn-base;
	color: $button-primary-text-color;
	background-color: $button-primary-color;
	border: rem(1px) solid $button-primary-color;

	.no-touchevents &:hover,
	.no-touchevents &:active {
		background-color: $button-primary-hover-color;
		color: $button-primary-hover-text-color;
	}

	.no-touchevents &:focus {
		color: $button-primary-hover-text-color;
		@include btn-box-shadow($button-primary-color);
	}
}

.btn-alt {
	@extend %btn-base;
	color: $button-secondary-text-color;
	border: rem(1px) solid $button-secondary-border-color;

	.no-touchevents &:hover,
	.no-touchevents &:active {
		background-color: $button-secondary-hover-color;
		color: $button-secondary-hover-text-color;
	}

	.no-touchevents &:focus {
		color: $button-secondary-hover-text-color;
		@include btn-box-shadow($button-secondary-border-color);
	}
}

.btn-payment-request-xxlarge-margin {
	// for some reason, the PR button doesn't have enough room to grow to the right
	width: 100% !important;
	margin-right: rem(150px) !important;
	display: block !important;
}

.btn-unstyled {
	border: none;
	background-color: transparent;
	padding: 0;
}

.btn-alert {
	@extend .btn-alt;
	color: $crimson;
	border: rem(1px) solid $bright-red;

	.no-touchevents &:hover,
	.no-touchevents &:active {
		color: $crimson;
		background-color: $rose;
	}

	.no-touchevents &:focus {
		color: $crimson;
		@include btn-box-shadow($bright-red);
	}
}

.btn-card {
	@extend .card;
	@extend .btn-alt;
	width: 100%;
}

.btn-card-body {
	@extend .card-body;
}

/***************************************************
	MODIFIERS
***************************************************/

/** SIZES
---------------------------------------- **/

// default and large
.btn-large,
.btn-alt,
.btn {
	display: block;
	width: 100%;
}

@mixin inline-btn {
	display: inline-block;
	width: auto;
}

// Equivalent to Mortar large buttons
.btn-medium {
	@include inline-btn;
}

// Equivalent to Mortar medium buttons
@mixin btn-small {
	@include inline-btn;
	font-size: rem(16px);
	line-height: 1.2;
}

.btn-small {
	@include btn-small;
}

// Equivalent to Mortar small buttons
@mixin btn-tiny {
	@include inline-btn;
	font-size: rem(14px);
	line-height: 1;
}

.btn-tiny {
	@include btn-tiny
}

.btn-small-for-medium-up {
	@media only screen and (min-width: $breakpoint-small) {
		@include btn-small;
	}
}

.btn-tiny-for-small-only {
	@media only screen and (max-width: $breakpoint-small) {
		@include btn-tiny;
	}
}

.btn-block {
	width: 100%;
	display: block;
}

.btn-block-for-small-only {
	width: 100%;
	display: block;
	margin-left: 0 !important;

	@media only screen and (min-width: $breakpoint-small) {
		width: auto;
		display: inline-block;
	}
}

/** SIBLINGS
---------------------------------------- **/

.btn-spacing-left {
	margin-left: rem(10px);
}

.btn-spacing-left-for-medium-up {
	@media only screen and (min-width: $breakpoint-small) {
		margin-left: rem(10px) !important;
	}
}

.btn-spacing-right {
	margin-right: rem(10px);
}

[class*=btn-]:not(.btn-block) + [class*=btn-]:not(.btn-block) {
	margin-left: 0.6em;

	@media only screen and (min-width: $breakpoint-medium) {
		margin-left: 1.3em;
	}
}



/***************************************************
	Offline Button Styles
***************************************************/

.site-offline .btn,
.site-offline .btn-alt,
.site-offline .btn-alert {
	opacity: .5;
	cursor: not-allowed;
	pointer-events: none;
}

.site-offline .btn.allow-offline,
.site-offline .btn-alt.allow-offline,
.site-offline .btn-alert.allow-offline{
	opacity: 1;
	cursor: pointer;
	pointer-events: auto;
}

/***************************************************
	BUTTON FORMATTING
***************************************************/

.btn-wrapper {
	&--separated {
		@include flexbox;
		@include justify-content(space-between);
	}
	&--justified {
		&--center {
			@include flexbox;
			@include justify-content(center);
		}
		&--right {
			@include flexbox;
			@include justify-content(flex-end);
		}
		&--right-for-medium-up {
			@media only screen and (min-width: $breakpoint-small) {
				@include flexbox;
				@include justify-content(flex-end);
			}
		}
		&--left {
			@include flexbox;
			@include justify-content(flex-start);
		}
	}
}
